import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import SearchFilter from "./shared/SearchFilter";
import useApi from "../hooks/useApi";
import businessApi from "../api/business";
import DetailsModal from "./shared/DetailsModal";
import {
  Box,
  Card,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";

const Business = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState<{
    title: string;
    data: any;
    loading: boolean;
    error: string;
    fromDate: "";
    toDate: "";
  }>({
    title: "",
    data: null,
    loading: false,
    error: "",
    fromDate: "",
    toDate: "",
  });

  // Initialize API hooks
  const getActiveBusinessDetailsApi = useApi(businessApi.checkActiveBusiness);
  const getRemovedBusinessDetailsApi = useApi(
    businessApi.checkRemovedBusinessDetailsApi
  );
  const getBusinessJoinedByDateApi = useApi(
    businessApi.checkBusinessJoinedByDate
  );
  const getAmountOfBenefitsRaisedApi = useApi(
    businessApi.checkAmountOfBenefitsRaised
  );

  const handleConfirm = async (apiHook, title, payload) => {
    setModalOpen(true);
    setModalData({
      title,
      data: null,
      loading: true,
      error: "",
      fromDate: payload.fromDate,
      toDate: payload.toDate,
    });

    try {
      console.log(`Calling API with payload:`, payload);
      const response = await apiHook.request(payload);
      console.log(`API Response:`, response);

      const { data, error } = response || {
        data: null,
        error: "Unexpected Error!",
      };

      if (error) {
        setModalData({
          title,
          data: null,
          loading: false,
          error,
          fromDate: payload.fromDate,
          toDate: payload.toDate,
        });
      } else if (data) {
        setModalData({
          title,
          data: data.data,
          loading: false,
          error: "",
          fromDate: payload.fromDate,
          toDate: payload.toDate,
        });
      } else {
        setModalData({
          title,
          data: null,
          loading: false,
          error: "No data found",
          fromDate: payload.fromDate,
          toDate: payload.toDate,
        });
      }
    } catch (err) {
      console.error(`API Error:`, err);
      setModalData({
        title,
        data: null,
        loading: false,
        error: "Unexpected Error!",
        fromDate: payload.fromDate,
        toDate: payload.toDate,
      });
    }
  };

  const handleClose = () => setModalOpen(false);

  const boxStyles = {
    backgroundColor: "#f2f3f5",
    padding: 2,
    height: "15px", // Set a fixed height
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <>
      <Container maxWidth="xl">
        <Card>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={3}>
              <Box
                sx={{
                  backgroundColor: "#f2f3f5",
                  padding: 2,
                }}
              >
                <Box sx={boxStyles}>
                  <Typography color={"#000000"} textAlign="center">
                    Active businesses
                  </Typography>
                </Box>
              </Box>
              <SearchFilter
                handleConfirm={(payload) =>
                  handleConfirm(
                    getActiveBusinessDetailsApi,
                    "Active Businesses",
                    payload
                  )
                }
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <Box
                sx={{
                  backgroundColor: "#f2f3f5",
                  padding: 2,
                }}
              >
                <Box sx={boxStyles}>
                  <Typography color={"#000000"} textAlign="center">
                    Removed businesses
                  </Typography>
                </Box>
              </Box>
              <SearchFilter
                handleConfirm={(payload) =>
                  handleConfirm(
                    getRemovedBusinessDetailsApi,
                    "Removed Businesses",
                    payload
                  )
                }
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <Box
                sx={{
                  backgroundColor: "#f2f3f5",
                  padding: 2,
                }}
              >
                <Box sx={boxStyles}>
                  <Typography color={"#000000"} textAlign="center">
                    Businesses joined by date
                  </Typography>
                </Box>
              </Box>
              <SearchFilter
                handleConfirm={(payload) =>
                  handleConfirm(
                    getBusinessJoinedByDateApi,
                    "Businesses Joined By Date",
                    payload
                  )
                }
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <Box
                sx={{
                  backgroundColor: "#f2f3f5",
                  padding: 2,
                }}
              >
                <Box sx={boxStyles}>
                  <Typography color={"#000000"} textAlign="center">
                    Amount of benefits raised
                  </Typography>
                </Box>
              </Box>
              <SearchFilter
                handleConfirm={(payload) =>
                  handleConfirm(
                    getAmountOfBenefitsRaisedApi,
                    "Amount Of Benefits Raised",
                    payload
                  )
                }
              />
            </Grid>
          </Grid>
        </Card>
      </Container>
      <DetailsModal
        open={modalOpen}
        handleClose={handleClose}
        title={modalData.title}
        data={modalData.data}
        loading={modalData.loading}
        error={modalData.error}
        fromDate={modalData.fromDate}
        toDate={modalData.toDate}
      />
    </>
  );
};

export default Business;
