import { useState } from "react";
import S3 from "react-aws-s3";

const useStorage = () => {
  const [uploading, setUploading] = useState<boolean>(false);
  const [uploadedUrl, setUploadedUrl] = useState<string | null>(null);
  const [uploadError, setUploadError] = useState<string | null>(null);

  const handleFileUpload = async (
    sourceFile: File,
    fileName: string,
    filePath: string
  ) => {
    try {
      setUploading(true);

      const config = {
        dirName: filePath,
        bucketName: process.env.REACT_APP_AWSS3_BUCKET_NAME,
        region: process.env.REACT_APP_AWSS3_REGION,
        accessKeyId: process.env.REACT_APP_AWSS3_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_AWSS3_SECRET_KEY,
      };

      const ReactS3Client = new S3(config);
      return await ReactS3Client.uploadFile(sourceFile, fileName).then(
        (data) => {
          if (data.status === 204) {
            setUploadedUrl(data.location);
          } else {
            setUploadError(data || "File upload failed");
          }

          return data;
        }
      );
    } catch (error: any) {
      setUploadError(error?.message || "File upload failed");
      return { error: error?.message || "File upload failed", url: null };
    } finally {
      setUploading(false);
    }
  };

  return {
    uploading,
    uploadedUrl,
    uploadError,
    handleFileUpload,
  };
};

export default useStorage;
