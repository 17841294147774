import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import i18n from "i18next";
import { useAuth } from "../../context/authProvider";
import SearchIcon from "@mui/icons-material/Search";
import {
  AppBar,
  Toolbar,
  Button,
  useMediaQuery,
  useTheme,
  IconButton,
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
  Menu,
  MenuItem,
  InputBase,
  Container,
  Grid,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import LogoutIcon from "@mui/icons-material/Logout";
import { useDispatch, useSelector } from "react-redux";
import { exportToExcel, exportToPdf } from "../../utils/Helper";
import { IAppState } from "../../types/common";

const MyNavbar = ({ handleDrawerToggle, drawerWidth }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { setToken } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const isLTR: boolean = i18n.language === "en";
  const { t } = useTranslation();

  const [mobileMenuAnchor, setMobileMenuAnchor] = useState(null);
  const isMobileMenuOpen = Boolean(mobileMenuAnchor);

  const handleMobileMenuOpen = (event) => {
    setMobileMenuAnchor(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMenuAnchor(null);
  };

  const handleSignOut = (): void => {
    setToken();
    navigate("/", { replace: true });
  };

  const signoutButtonStyle: any = {
    backgroundColor: "white",
    color: "black",
    borderRadius: "20px",
    "&:hover": {
      backgroundColor: "#c6c7c8",
    },
    textTransform: "unset",
  };

  const dispatch = useDispatch();
  const searchQuery = useSelector((state: any) => state.searchQuery);
  let selectedBusinessIDs: string[] = useSelector(
    (state: IAppState) => state.selectedBusinessIDs
  );

  let businessList: any[] = useSelector(
    (state: IAppState) => state.businessList
  );

  if (businessList.length && selectedBusinessIDs.length) {
    businessList = businessList?.filter((item) =>
      selectedBusinessIDs.includes(item.businessId)
    );
  }

  const handleSearch = (event) => {
    const query = event.target.value;
    dispatch({ type: "SET_SEARCH_QUERY", payload: query });

    if (location.pathname !== "/") {
      navigate("/", { replace: true });
    }
  };

  const handleExportToExcel = () => {
    exportToExcel(businessList);
  };

  const handleExportToPdf = () => {
    exportToPdf(businessList);
  };

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMenuAnchor}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          backgroundColor: "#FFFFFF",
          borderRadius: 20,
          padding: "2px 10px",
          border: "1px solid #000000",
          ml: 1,
          mr: 1,
          mb: 2,
        }}
      >
        <SearchIcon sx={{ color: "#000000" }} />
        <InputBase
          placeholder={t("lbl.search_placeholder")}
          value={searchQuery}
          onChange={handleSearch}
          sx={{ ml: 1, flex: 1, color: "#000000" }}
        />
      </Box>
      <MenuItem>
        <Button
          component={Link}
          to="/open-business"
          size="small"
          variant="text"
        >
          Open a new Business
        </Button>
      </MenuItem>
      <MenuItem>
        <Button
          component={Link}
          to="/certified-mail"
          size="small"
          variant="text"
        >
          Certified mail
        </Button>
      </MenuItem>
      <MenuItem>
        <Button
          onClick={handleExportToExcel}
          startIcon={<img src="./xlsx-24.png" width={20} alt="XLSX" />}
        >
          Export to Excel
        </Button>
      </MenuItem>
      <MenuItem>
        <Button
          onClick={handleExportToPdf}
          startIcon={<img src="./pdf_filetype_icon.png" width={20} alt="PDF" />}
        >
          Export to PDF
        </Button>
      </MenuItem>
      <MenuItem onClick={handleSignOut}>
        <Button size="small" variant="text" startIcon={<LogoutIcon />}>
          {t("lbl.logout")}
        </Button>
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: isLTR ? { sm: `${drawerWidth}px` } : 0,
          mr: !isLTR ? { sm: `${drawerWidth}px` } : 0,
          backgroundColor: "#FE0000",
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            padding: isSmallScreen ? 0 : 1,
          }}
        >
          <Toolbar sx={{ justifyContent: "space-between" }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="div"
              sx={{ textAlign: "center", flexGrow: 1 }}
            >
              {t("lbl.hello")} Ron
            </Typography>
            {isSmallScreen ? (
              <IconButton
                color="inherit"
                aria-label="open menu"
                edge="end"
                onClick={handleMobileMenuOpen}
              >
                <MoreVertIcon />
              </IconButton>
            ) : (
              <Box
                sx={{ flexGrow: 1, display: "flex", justifyContent: "center" }}
              >
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={6} sm={4}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#FFFFFF",
                        borderRadius: 20,
                        padding: "5px 10px",
                        width: "100%",
                      }}
                    >
                      <SearchIcon sx={{ color: "#000000" }} />
                      <InputBase
                        placeholder={t("lbl.search_placeholder")}
                        value={searchQuery}
                        onChange={handleSearch}
                        sx={{ ml: 1, flex: 1, color: "#000000" }}
                      />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Button
                      component={Link}
                      to="/open-business"
                      variant="contained"
                      sx={{
                        backgroundColor: "#FFFFFF",
                        textTransform: "capitalize",
                        color: "#FE0000",
                        borderRadius: 10,
                        "&:hover": {
                          backgroundColor: "#c6c7c8",
                        },
                      }}
                    >
                      Open a new business
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      component={Link}
                      to="/certified-mail"
                      variant="contained"
                      sx={{
                        backgroundColor: "#FFFFFF",
                        textTransform: "capitalize",
                        color: "#FE0000",
                        borderRadius: 10,
                        "&:hover": {
                          backgroundColor: "#c6c7c8",
                        },
                      }}
                    >
                      Certified mail
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "#FFFFFF",
                        textTransform: "capitalize",
                        color: "#FE0000",
                        borderRadius: 10,
                        "&:hover": {
                          backgroundColor: "#c6c7c8",
                        },
                      }}
                      onClick={handleExportToExcel}
                    >
                      <img src="./xlsx-24.png" width={20} alt="XLSX" />
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "#FFFFFF",
                        textTransform: "capitalize",
                        color: "#FE0000",
                        borderRadius: 10,
                        "&:hover": {
                          backgroundColor: "#c6c7c8",
                        },
                      }}
                      onClick={handleExportToPdf}
                    >
                      <img src="./pdf_filetype_icon.png" width={20} alt="PDF" />
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "#FFFFFF",
                        textTransform: "capitalize",
                        color: "#FE0000",
                        borderRadius: 10,
                        "&:hover": {
                          backgroundColor: "#c6c7c8",
                        },
                      }}
                      startIcon={<LogoutIcon />}
                      onClick={handleSignOut}
                    >
                      {t("lbl.logout")}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            )}
          </Toolbar>
        </Container>
      </AppBar>
      {renderMobileMenu}
    </>
  );
};

export default MyNavbar;
