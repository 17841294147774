import React, { useState } from "react";
import { Card, CardContent, Collapse, Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DateShortcuts from "./DateShortcuts";
import dayjs, { Dayjs } from "dayjs";
import { Button, Button as MUIButton } from "@mui/material";

interface SearchFilterProps {
  handleConfirm?: (payload: { fromDate: string; toDate: string }) => void;
}

const SearchFilter: React.FC<SearchFilterProps> = ({ handleConfirm }) => {
  const [expanded, setExpanded] = useState(true);
  const [dateRange, setDateRange] = useState<[Dayjs | null, Dayjs | null]>([
    null,
    null,
  ]);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleConfirmClick = () => {
    if (dateRange[0] && dateRange[1] && handleConfirm) {
      const payload = {
        fromDate: dayjs(dateRange[0]).format("YYYY-MM-DD"),
        toDate: dayjs(dateRange[1]).format("YYYY-MM-DD"),
      };
      console.log(payload, "<---payload");
      handleConfirm(payload);
    }
  };

  return (
    <Card>
      <CardContent>
        <Typography
          variant="subtitle1"
          component="div"
          onClick={handleExpandClick}
        >
          {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />} Time
          Range
        </Typography>
      </CardContent>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent
          sx={{
            "& .MuiPaper-root": {
              boxShadow: "none",
            },
          }}
        >
          <DateShortcuts dateRange={dateRange} setDateRange={setDateRange} />
          <MUIButton
            variant="contained"
            onClick={handleConfirmClick}
            sx={{
              backgroundColor: "#fe0000 !important",
              color: "white",
              borderRadius: "20px",
              "&:hover": {
                backgroundColor: "#c6c7c8",
              },
              textTransform: "unset",
              width: "100%",
              marginTop: "15px",
            }}
          >
            Confirm
          </MUIButton>
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default SearchFilter;
