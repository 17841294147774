import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  CircularProgress,
  Box,
  IconButton,
  Button,
} from "@mui/material";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

import CloseIcon from "@mui/icons-material/Close";

interface DetailsModalProps {
  open: boolean;
  handleClose: () => void;
  title: string;
  data: any[];
  loading: boolean;
  error: string;
  fromDate: string;
  toDate: string;
}

const DetailsModal: React.FC<DetailsModalProps> = ({
  open,
  handleClose,
  title,
  data,
  loading,
  error,
  fromDate,
  toDate,
}) => {
  // Define column configurations for different titles
  const columns: Record<string, GridColDef[]> = {
    "Active Businesses": [
      { field: "_id", headerName: "ID", width: 90 },
      { field: "businessName", headerName: "Business Name", width: 150 },
      { field: "businessAddress", headerName: "Business Address", width: 200 },
      { field: "businessPhoneNumber", headerName: "Phone Number", width: 150 },
      { field: "email", headerName: "Email", width: 180 },
      { field: "city", headerName: "City", width: 120 },
      { field: "companyNumber", headerName: "Company Number", width: 150 },
      { field: "companyName", headerName: "Company Name", width: 150 },
      { field: "isKosher", headerName: "Is Kosher", width: 100 },
      { field: "foodType", headerName: "Food Type", width: 180 },
      { field: "businessType", headerName: "Business Type", width: 180 },
    ],
    "Removed Businesses": [
      { field: "_id", headerName: "ID", width: 90 },
      { field: "businessName", headerName: "Business Name", width: 150 },
      { field: "businessAddress", headerName: "Business Address", width: 200 },
      { field: "email", headerName: "Email Address", width: 200 },
      { field: "businessPhoneNumber", headerName: "Phone Number", width: 200 },
      { field: "deactivateDate", headerName: "Date Removed", width: 150 },
    ],
    "Businesses Joined By Date": [
      { field: "_id", headerName: "ID", width: 90 },
      { field: "businessName", headerName: "Business Name", width: 150 },
      { field: "businessAddress", headerName: "Business Address", width: 250 },
      { field: "createdAt", headerName: "Joining Date", width: 150 },
    ],
    "Amount Of Benefits Raised": [
      { field: "_id", headerName: "ID", width: 90 },
      { field: "businessName", headerName: "Business Name", width: 150 },
      { field: "benefitsRaised", headerName: "Amount Raised", width: 150 },
      { field: "businessAddress", headerName: "Business Address", width: 250 },
    ],
  };

  const handleExportPDF = () => {
    const doc = new jsPDF({
      orientation: "landscape", // Switch to landscape mode
      unit: "mm",
      format: "a2", // Use a3 size
    });
    doc.text(`${title}_${fromDate} - ${toDate}`, 20, 10);
    const columnHeaders = columns[title]?.map((col) => col.headerName) || [];
    const rows = data.map(
      (row) => columns[title]?.map((col) => row[col.field]) || []
    );

    (doc as any).autoTable({
      head: [columnHeaders],
      body: rows,
      startY: 30,
      styles: {
        fontSize: 8,
        cellWidth: "wrap", // Let cells wrap text if needed
      },
      columnStyles: {
        // Example of setting specific column widths
        0: { cellWidth: 20 }, // First column width
        1: { cellWidth: 20 }, // Second column width
        // Add more column styles as needed
      },
      theme: "striped",
      margin: { top: 20 },
    });
    doc.save(`${title}_${fromDate} - ${toDate}.pdf`);
  };

  const handleExportExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(data);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, title);
    XLSX.writeFile(workbook, `${title}_${fromDate} - ${toDate}.xlsx`);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: 10,
        }}
      >
        <DialogTitle>{title}</DialogTitle>

        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          paddingLeft: 3,
          gap: 2,
        }}
      >
        <IconButton onClick={handleExportExcel}>
          <img src="./xlsx-24.png" width={20} alt="XLSX" />
        </IconButton>
        <IconButton onClick={handleExportPDF}>
          <img src="./pdf_filetype_icon.png" width={20} alt="XLSX" />
        </IconButton>
      </Box>

      <DialogContent>
        {loading && <CircularProgress />}
        {error && <Typography color="error">{error}</Typography>}
        {data && (
          <Box sx={{ height: 400, width: "100%" }}>
            <DataGrid
              rows={data.map((row) => ({
                id: row._id,
                ...row,
              }))}
              columns={columns[title] || []}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              pageSizeOptions={[5]}
              disableRowSelectionOnClick
            />
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default DetailsModal;
