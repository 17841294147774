import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Button,
  TextField,
  MenuItem,
  Typography,
} from "@mui/material";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { ICreateCouponDto } from "../../types/coupon";

const CouponModal = ({
  showModal,
  toggleModal,
  couponDetails,
  handleSaveCoupon,
}) => {
  const { t } = useTranslation();

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    control,
    setValue,
  } = useForm<ICreateCouponDto>();

  useEffect(() => {
    if (couponDetails) {
      const entries = Object.entries(couponDetails);
      entries.forEach(([key, value]: [any, any]) => {
        setValue(key, value);
      });
    } else reset();
  }, [couponDetails]);

  const onSubmit: SubmitHandler<any> = async (data) => {
    if (data) {
      handleSaveCoupon(data);
      toggleModal();
      reset();
    }
  };

  return (
    <Dialog
      open={showModal}
      onClose={() => {
        toggleModal();
        reset();
      }}
      maxWidth="md"
      fullWidth
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{couponDetails ? "Edit" : "Add"} Coupon</DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                {t("All are required fields")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="name"
                control={control}
                defaultValue=""
                rules={{ required: t("Name is required") }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t("Name")}
                    variant="outlined"
                    fullWidth
                    error={!!errors.name}
                    helperText={errors.name ? t(errors.name.message) : ""}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "10px", // Increase border radius
                        borderColor: "#E3E3E3", // Change border color
                        borderWidth: "2px", // Make border solid and 2px
                        "& fieldset": {
                          borderColor: "#E3E3E3", // Ensure initial border color is set
                          borderWidth: "3px", // Ensure initial border width is set
                        },
                        "&:hover fieldset": {
                          borderColor: "#E3E3E3", // Change hover border color
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#FE0000", // Keep the focused border color
                        },
                      },
                      "& .MuiInputLabel-root": {
                        "&.Mui-focused": {
                          color: "#FE0000",
                        },
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="code"
                control={control}
                defaultValue=""
                rules={{ required: t("Code is required") }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t("Code")}
                    variant="outlined"
                    fullWidth
                    error={!!errors.code}
                    helperText={errors.code ? t(errors.code.message) : ""}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "10px", // Increase border radius
                        borderColor: "#E3E3E3", // Change border color
                        borderWidth: "2px", // Make border solid and 2px
                        "& fieldset": {
                          borderColor: "#E3E3E3", // Ensure initial border color is set
                          borderWidth: "3px", // Ensure initial border width is set
                        },
                        "&:hover fieldset": {
                          borderColor: "#E3E3E3", // Change hover border color
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#FE0000", // Keep the focused border color
                        },
                      },
                      "& .MuiInputLabel-root": {
                        "&.Mui-focused": {
                          color: "#FE0000",
                        },
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="discountType"
                control={control}
                rules={{ required: t("Discount type is required") }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    select
                    label={t("Discount Type")}
                    variant="outlined"
                    fullWidth
                    error={!!errors.discountType}
                    helperText={
                      errors.discountType ? t(errors.discountType.message) : ""
                    }
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "10px", // Increase border radius
                        borderColor: "#E3E3E3", // Change border color
                        borderWidth: "2px", // Make border solid and 2px
                        "& fieldset": {
                          borderColor: "#E3E3E3", // Ensure initial border color is set
                          borderWidth: "3px", // Ensure initial border width is set
                        },
                        "&:hover fieldset": {
                          borderColor: "#E3E3E3", // Change hover border color
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#FE0000", // Keep the focused border color
                        },
                      },
                      "& .MuiInputLabel-root": {
                        "&.Mui-focused": {
                          color: "#FE0000",
                        },
                      },
                    }}
                  >
                    <MenuItem value="">
                      <em>{t("Select")}</em>
                    </MenuItem>
                    <MenuItem value="fixed">{t("Fixed")}</MenuItem>
                    <MenuItem value="percentage">{t("Percentage")}</MenuItem>
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="value"
                control={control}
                defaultValue=""
                rules={{ required: t("Value is required") }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t("Value")}
                    variant="outlined"
                    fullWidth
                    error={!!errors.value}
                    helperText={errors.value ? t(errors.value.message) : ""}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "10px", // Increase border radius
                        borderColor: "#E3E3E3", // Change border color
                        borderWidth: "2px", // Make border solid and 2px
                        "& fieldset": {
                          borderColor: "#E3E3E3", // Ensure initial border color is set
                          borderWidth: "3px", // Ensure initial border width is set
                        },
                        "&:hover fieldset": {
                          borderColor: "#E3E3E3", // Change hover border color
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#FE0000", // Keep the focused border color
                        },
                      },
                      "& .MuiInputLabel-root": {
                        "&.Mui-focused": {
                          color: "#FE0000",
                        },
                      },
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => toggleModal()}>{t("Cancel")}</Button>
          <Button
            type="submit"
            variant="contained"
            sx={{
              backgroundColor: "#FE0000",
              "&:hover": {
                backgroundColor: "#FE0000",
              },
            }}
          >
            {t("Save")}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CouponModal;
