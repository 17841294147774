import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Card, Container, Grid, Typography } from "@mui/material";
import MyTable from "./inc/MyTable";
import SearchFilter from "./shared/SearchFilter";

const Expense = () => {
  return (
    <Container maxWidth="xl">
      <Card>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={4}>
            <Box
              sx={{
                backgroundColor: "#f2f3f5",
                padding: 2,
              }}
            >
              <Typography color={"#000000"} textAlign="center">
                Total expenses by date
              </Typography>
            </Box>

            <SearchFilter />
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
};

export default Expense;
