import * as XLSX from "xlsx";
const { jsPDF } = require("jspdf");
const { autoTable } = require("jspdf-autotable");
const { format } = require("date-fns");

export function capitalizeFirstLetter(inputString) {
  return inputString.replace(/\b\w/g, function (char) {
    return char.toUpperCase();
  });
}

function generateRandomFilename() {
  const randomString = Math.random().toString(36).substring(2, 8);
  const now = new Date();
  const timestamp = `${now.getFullYear()}${(now.getMonth() + 1)
    .toString()
    .padStart(
      2,
      "0"
    )}${now.getDate()}${now.getHours()}${now.getMinutes()}${now.getSeconds()}`;
  const filename = `export_${timestamp}_${randomString}`;
  return filename;
}

export const exportToExcel = (data) => {
  const ws = XLSX.utils.json_to_sheet(data);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const blob = new Blob([wbout], { type: "application/octet-stream" });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = `${generateRandomFilename()}.xlsx`;
  a.click();
};

export const exportToPdf = (data) => {
  const doc = new jsPDF("l");
  const columns = [
    "Business Name",
    "Address",
    "Company Number",
    "Company Name",
    "Phone",
    "Contact Name",
    "Email",
    "Registration Date",
  ];

  const columnStyles = {
    0: { cellWidth: 30 },
    1: { cellWidth: 30 },
    2: { cellWidth: 30 },
    3: { cellWidth: 30 },
    4: { cellWidth: 30 },
    5: { cellWidth: 30 },
    6: { cellWidth: 50 },
    7: { cellWidth: 30 },
  };

  const rowsPerPage = 10;
  var totalPages = Math.ceil(data.length / rowsPerPage);

  for (var i = 0; i < totalPages; i++) {
    if (i > 0) {
      doc.addPage();
    }

    var startIndex = i * rowsPerPage;
    var endIndex = Math.min((i + 1) * rowsPerPage, data.length);

    var pageData = data
      .slice(startIndex, endIndex)
      .map((item, index) => [
        item.businessName,
        item.businessAddress,
        item.companyNumber,
        item.companyName,
        item.businessPhoneNumber,
        item.contactName,
        item.email,
        format(new Date(item.createdAt), "dd/MMM/yyyy"),
      ]);

    doc.autoTable({
      head: [columns],
      body: pageData,
      columnStyles: columnStyles,
      startY: 20,
      didDrawPage: function (data) {
        // Header
        doc.setFontSize(20);
        doc.setTextColor(40);
        doc.text("Registered Business List", data.settings.margin.left, 15);

        // Footer
        var str = "Page " + (i + 1);

        doc.setFontSize(10);
        var pageSize = doc.internal.pageSize;
        var pageHeight = pageSize.height
          ? pageSize.height
          : pageSize.getHeight();
        doc.text(str, data.settings.margin.left, pageHeight - 10);
      },
    });
  }
  doc.save(`${generateRandomFilename()}.pdf`);
};
