import Table from "@mui/material/Table";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  InputBase,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import MyTable from "./inc/MyTable";
import SearchFilter from "./shared/SearchFilter";
import { useTranslation } from "react-i18next";

const boxStyles = {
  backgroundColor: "#f2f3f5",
  padding: 2,
  height: "15px", // Set a fixed height
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

const Customer = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Container maxWidth="xl">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: 5,
        }}
      >
        <Typography sx={{ color: "#000000" }} variant="h5">
          Realizations by business
        </Typography>
        <Box
          sx={{
            marginTop: 2,
            display: "flex",
            alignItems: "center",
            backgroundColor: "#FFFFFF",
            borderRadius: 20,
            padding: "5px 10px",
            width: isSmallScreen ? "100%" : "50%",
            border: "3px solid #E3E3E3",
          }}
        >
          <SearchIcon sx={{ color: "#000000" }} />
          <InputBase
            placeholder={t("lbl.search_placeholder")}
            // value={searchQuery}
            // onChange={handleSearch}
            sx={{ ml: 1, flex: 1, color: "#000000" }}
          />
        </Box>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              marginTop: 2,
              gap: 2,
            }}
          >
            <DatePicker
              label="From date"
              // value={dateRange[0]}
              // onChange={(date) => setDateRange([date, dateRange[1]])}
              slotProps={{ textField: { size: "small" } }}
              sx={{ width: isSmallScreen ? "120px" : "150px" }}
            />
            <DatePicker
              label="From date"
              // value={dateRange[0]}
              // onChange={(date) => setDateRange([date, dateRange[1]])}
              slotProps={{ textField: { size: "small" } }}
              sx={{ width: isSmallScreen ? "120px" : "150px" }}
            />
          </Box>
        </LocalizationProvider>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            marginTop: 2,
            gap: 2,
          }}
        >
          <Button
            variant="contained"
            sx={{
              width: isSmallScreen ? "120px" : "150px",
              backgroundColor: "#ffffff",
              color: "#000000",
              textTransform: "capitalize",
              borderRadius: 20,
              border: "2px solid #E3E3E3",
              "&:hover": {
                backgroundColor: "#ffffff",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{
              width: isSmallScreen ? "120px" : "150px",
              backgroundColor: "#FE0000",
              color: "#FFFFFF",
              textTransform: "capitalize",
              borderRadius: 20,
              border: "2px solid #FE0000",
              "&:hover": {
                backgroundColor: "#FE0000",
              },
            }}
          >
            Confirm
          </Button>
        </Box>
      </Box>
      <Card>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={3}>
            <Box
              sx={{
                backgroundColor: "#f2f3f5",
                padding: 2,
              }}
            >
              <Box sx={boxStyles}>
                <Typography color={"#000000"} textAlign="center">
                  Time spent in the app
                </Typography>
              </Box>
            </Box>
            <SearchFilter />
          </Grid>
          <Grid item xs={12} lg={3}>
            <Box
              sx={{
                backgroundColor: "#f2f3f5",
                padding: 2,
              }}
            >
              <Box sx={boxStyles}>
                <Typography color={"#000000"} textAlign="center">
                  Number of logins to the application
                </Typography>
              </Box>
            </Box>
            <SearchFilter />
          </Grid>
          <Grid item xs={12} lg={3}>
            <Box
              sx={{
                backgroundColor: "#f2f3f5",
                padding: 2,
              }}
            >
              <Box sx={boxStyles}>
                <Typography color={"#000000"} textAlign="center">
                  Amount of benefit realization
                </Typography>
              </Box>
            </Box>
            <SearchFilter />
          </Grid>
          <Grid item xs={12} lg={3}>
            <Box
              sx={{
                backgroundColor: "#f2f3f5",
                padding: 2,
              }}
            >
              <Box sx={boxStyles}>
                <Typography color={"#000000"} textAlign="center">
                  Amount of downloads (active customers)
                </Typography>
              </Box>
            </Box>
            <SearchFilter />
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
};

export default Customer;
