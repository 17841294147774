import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

const PromotionDeactivateConfirmation = ({
  isModalVisible,
  setIsModalVisible,
  handleConfirmAction,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Modal
        show={isModalVisible}
        onHide={() => setIsModalVisible(false)}
        size="sm"
        centered
      >
        <Modal.Body dir={`${i18n.language === "en" ? "ltr" : "rtl"}`}>
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ flexDirection: "column" }}
          >
            <p className="mt-3">
              {t("lbl.are_you_sure_deactivate_promotion_text")}
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer dir={`${i18n.language === "en" ? "ltr" : "rtl"}`}>
          <Button
            variant="primary"
            onClick={() => {
              handleConfirmAction();
              setIsModalVisible(false);
            }}
          >
            {t("lbl.yes")}
          </Button>
          <Button
            variant="default"
            onClick={() => {
              setIsModalVisible(false);
            }}
          >
            {t("lbl.no")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PromotionDeactivateConfirmation;
