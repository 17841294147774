import * as React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Grid, Button } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";

interface DateShortcutsProps {
  dateRange: [Dayjs | null, Dayjs | null];
  setDateRange: (range: [Dayjs | null, Dayjs | null]) => void;
}

const DateShortcuts: React.FC<DateShortcutsProps> = ({
  dateRange,
  setDateRange,
}) => {
  const rows = [
    { value: "today", label: "Today" },
    { value: "yesterday", label: "Yesterday" },
    { value: "thisWeek", label: "This week" },
    { value: "thisMonth", label: "This month" },
    { value: "lastMonth", label: "Last month" },
    { value: "3lastMonth", label: "3 last month" },
    { value: "thisYear", label: "This year" },
  ];

  const handleShortcutClick = (shortcut) => {
    const today = dayjs();
    let startDate: Dayjs | null = null,
      endDate: Dayjs | null = null;

    switch (shortcut) {
      case "today":
        startDate = today;
        endDate = today;
        break;
      case "yesterday":
        startDate = today.subtract(1, "day");
        endDate = today.subtract(1, "day");
        break;
      case "thisWeek":
        startDate = today.startOf("week");
        endDate = today.endOf("week");
        break;
      case "thisMonth":
        startDate = today.startOf("month");
        endDate = today.endOf("month");
        break;
      case "lastMonth":
        startDate = today.subtract(1, "month").startOf("month");
        endDate = today.subtract(1, "month").endOf("month");
        break;
      case "3lastMonth":
        startDate = today.subtract(2, "month").startOf("month");
        endDate = today.endOf("month");
        break;
      case "thisYear":
        startDate = today.startOf("year");
        endDate = today.endOf("year");
        break;
      default:
        break;
    }

    setDateRange([startDate, endDate]);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid>
          <DatePicker
            label="From date"
            value={dateRange[0]}
            onChange={(date) => setDateRange([date, dateRange[1]])}
            slotProps={{ textField: { size: "small" } }}
            sx={{ width: "150px" }}
          />
        </Grid>
        <Grid item sx={{ margin: "10px" }}>
          <DatePicker
            label="To date"
            value={dateRange[1]}
            onChange={(date) => setDateRange([dateRange[0], date])}
            slotProps={{ textField: { size: "small" } }}
            sx={{ width: "150px" }}
          />
        </Grid>
      </Grid>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="flex-end"
        spacing={1}
      >
        {rows.map((row) => (
          <Grid item key={row.value}>
            <Button
              variant="text"
              onClick={() => handleShortcutClick(row.value)}
            >
              {row.label}
            </Button>
          </Grid>
        ))}
      </Grid>
    </LocalizationProvider>
  );
};

export default DateShortcuts;
