import { Button, Modal, Form, Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { useEffect, useState } from "react";
import { Grid, Rating, Typography } from "@mui/material";
import { IEditComments, IPushNotificationDto } from "../../types/common";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

const CommentsModal = ({
  showModal,
  toggleModal,
  commentsDetails,
  handleUpdateComments,
}) => {
  const { t } = useTranslation();

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    setValue,
  } = useForm<any>();

  useEffect(() => {
    if (commentsDetails) {
      setValue("comments", commentsDetails?.commentsText);
    }
  }, [commentsDetails]);

  const onSubmit: SubmitHandler<any> = async (data) => {
    if (data) {
      handleUpdateComments(data, commentsDetails?.isDeactivateComments);
      toggleModal();
    }
  };

  return (
    <>
      <Modal show={showModal} onHide={toggleModal} centered>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body dir={`${i18n.language === "en" ? "ltr" : "rtl"}`}>
            <Grid container spacing={2}>
              {/* <Grid item>
                <div
                  style={{
                    width: "90px",
                    height: "90px",
                    overflow: "hidden",
                    borderRadius: "50%",
                  }}
                >
                  <Image
                    src={businessDetails?.businessLogoFile ?? ""}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </div>
              </Grid> */}
              <Grid item xs={12} sm container>
                <Grid item xs container direction="column" spacing={2}>
                  <Grid item xs>
                    <Typography
                      gutterBottom
                      variant="subtitle1"
                      component="div"
                    >
                      Edit{" "}
                      {commentsDetails?.isDeactivateComments
                        ? "Deactivate"
                        : "Activate"}{" "}
                      Comments
                    </Typography>
                    {/* <Rating name="size-small" defaultValue={2} size="small" /> */}
                  </Grid>
                  <Grid item>
                    <Form.Group controlId="smsModal.text">
                      <Controller
                        name="comments"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <Form.Control {...field} type="textarea" />
                        )}
                      />
                    </Form.Group>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Modal.Body>
          <Modal.Footer dir={`${i18n.language === "en" ? "ltr" : "rtl"}`}>
            <Button variant="default" onClick={() => toggleModal()}>
              {"Cancel"}
            </Button>
            <Button variant="primary" type="submit">
              {"Save"}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default CommentsModal;
