import { Button, Modal, Form, Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { useState } from "react";
import { Grid, Rating, Typography } from "@mui/material";
import { IPushNotificationDto } from "../../types/common";

const SMSModal = ({
  showModal,
  toggleModal,
  businessDetails,
  handleSendNotification,
}) => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState<IPushNotificationDto | undefined>(
    undefined
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData) {
      formData.phoneNumber = businessDetails.businessPhoneNumber;
      handleSendNotification(formData);
      toggleModal();
    }
  };

  return (
    <>
      <Modal show={showModal} onHide={toggleModal} centered>
        <Form onSubmit={handleSubmit}>
          <Modal.Body dir={`${i18n.language === "en" ? "ltr" : "rtl"}`}>
            <Grid container spacing={2}>
              <Grid item>
                <div
                  style={{
                    width: "90px",
                    height: "90px",
                    overflow: "hidden",
                    borderRadius: "50%",
                  }}
                >
                  <Image
                    src={businessDetails?.businessLogoFile ?? ""}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm container>
                <Grid item xs container direction="column" spacing={2}>
                  <Grid item xs>
                    <Typography
                      gutterBottom
                      variant="subtitle1"
                      component="div"
                    >
                      Send SMS notification
                    </Typography>
                    {/* <Rating name="size-small" defaultValue={2} size="small" /> */}
                  </Grid>
                  <Grid item>
                    <Form.Group controlId="smsModal.text">
                      <Form.Control
                        as="textarea"
                        rows={4}
                        placeholder={"Write any text here..."}
                        onChange={(e) =>
                          setFormData({ ...formData, message: e.target.value })
                        }
                      />
                    </Form.Group>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Modal.Body>
          <Modal.Footer dir={`${i18n.language === "en" ? "ltr" : "rtl"}`}>
            <Button variant="default" onClick={() => toggleModal()}>
              {"Cancel"}
            </Button>
            <Button variant="primary" type="submit">
              {"Send"}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default SMSModal;
