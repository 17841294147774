import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import SearchFilter from "./shared/SearchFilter";
import { Chart } from "react-google-charts";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
} from "@mui/material";

const Revenue = () => {
  const { t } = useTranslation();

  const data = [
    ["Quarer", "Revenue per quarter"],
    ["Q1", 50],
    ["Q2", 25],
    ["Q3", 20],
    ["Q4", 5],
  ];

  const options = {
    is3D: true,
    legend: "none",
  };

  return (
    <Container maxWidth="xl">
      <Card>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={4}>
            <Box
              sx={{
                backgroundColor: "#f2f3f5",
                padding: 2,
              }}
            >
              <Typography color={"#000000"} textAlign="center">
                {t("lbl.total_revenue_by_date")}
              </Typography>
            </Box>

            <SearchFilter />
          </Grid>
          <Grid item xs={12} lg={4}>
            <Box
              sx={{
                backgroundColor: "#f2f3f5",
                padding: 2,
              }}
            >
              <Typography color={"#000000"} textAlign="center">
                {t("lbl.total_revenue_by_quarter")}
              </Typography>
            </Box>

            <Chart
              chartType="PieChart"
              data={data}
              options={options}
              width="auto"
              height="auto"
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <Box
              sx={{
                backgroundColor: "#f2f3f5",
                padding: 2,
              }}
            >
              <Typography color={"#000000"} textAlign="center">
                {t("lbl.amount_of_cancellations")}
              </Typography>
            </Box>

            <SearchFilter />
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
};

export default Revenue;
