import client from "./client";
import { IPushNotificationDto } from "../types/common";

const BACKEND_API_PREFIX = process.env.REACT_APP_PROD_AUTH_API_PREFIX;

client.defaults.baseURL = BACKEND_API_PREFIX;

const sendNotification = (payload: IPushNotificationDto) => {
  return client.post(process.env.REACT_APP_SMS_NOTIFICATIONS ?? "", payload);
};

export default {
  sendNotification,
};
