import React, { useEffect, useState } from "react";
import "./../css/login.css";

import { useTranslation } from "react-i18next";
import FileUploadButton from "./inc/FileUploadButton";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { Link, useNavigate, useParams } from "react-router-dom";
import i18n from "i18next";
import { IBusinessRegRequest, IBusinessResponseDto } from "../types/business";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useSnackbar } from "../context/snackbarContext";
import { capitalizeFirstLetter } from "../utils/Helper";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import useApi from "../hooks/useApi";
import businessApi from "../api/business";
import { IResponseModel } from "../types/common";
import useStorage from "../hooks/useStorage";
import { v4 as uuidv4 } from "uuid";

const OpenNewBusiness = () => {
  const { t } = useTranslation();

  const [businessDetails, setBusinessDetails] = useState<
    IBusinessResponseDto | undefined
  >(undefined);

  const { id } = useParams();
  const navigate = useNavigate();

  const registerBusinessApi = useApi(businessApi.registerBusiness);
  const editBusinessApi = useApi(businessApi.editBusiness);
  const getBusinessDetailsApi = useApi(businessApi.getBusinessDetails);
  const s3StorageService = useStorage();

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    setValue,
  } = useForm<IBusinessRegRequest>();

  const { showSnackbar } = useSnackbar();

  const onSubmit: SubmitHandler<IBusinessRegRequest> = async (data) => {
    if (data.businessLogoFile instanceof File) {
      let phoneNumber = data.businessPhoneNumber.replace(/\+/g, "");
      const fileUploadResponse = await s3StorageService.handleFileUpload(
        data.businessLogoFile,
        `${uuidv4()}-${new Date().toISOString()}`,
        `business/${phoneNumber}/businessLogo`
      );
      data.businessLogoFile =
        fileUploadResponse?.location ?? data.businessLogoFile;
    }

    if (id) await editBusinessApi.request(id, data);
    else await registerBusinessApi.request(data);
  };

  const foodTypes: string[] = [
    "Pizza",
    "Burger",
    "Sushi",
    "Ice cream",
    "Drinks",
    "Israeli",
    "American food",
    "Italian food",
    "Salad",
    "Mexican",
    "Market",
    "Bakery",
    "Thai",
    "Coffee carts",
  ];

  useEffect(() => {
    if (id) {
      getBusinessDetailsApi.request(id);
    }
  }, []);

  useEffect(() => {
    const businessResponse: any = getBusinessDetailsApi?.data;
    if (businessResponse) {
      if (businessResponse?.data) {
        setBusinessDetails(businessResponse?.data);

        const entries = Object.entries(businessResponse?.data);
        entries.forEach(([key, value]: [any, any]) => {
          setValue(key, value);
        });
      } else {
        showSnackbar(businessResponse?.message || "No business details found");
      }
    }
  }, [getBusinessDetailsApi?.data]);

  useEffect(() => {
    let response: IResponseModel =
      registerBusinessApi?.data ?? editBusinessApi?.data;
    if (response) {
      if (response?.isSuccess) {
        showSnackbar(response?.message);

        const timer = setTimeout(() => {
          navigate("/", { replace: true });
        }, 3000);

        return () => clearTimeout(timer);
      } else {
        showSnackbar(response?.message || "Something went wrong");
      }
    }
  }, [registerBusinessApi?.data, editBusinessApi?.data]);

  return (
    <>
      <Container maxWidth="xl">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FileUploadButton
            control={control}
            errors={errors}
            fileSource={businessDetails?.businessLogoFile ?? undefined}
          />
          <Box>
            <Typography
              sx={{
                textAlign: "center",
                marginTop: 2,
                color: "#000000",
              }}
            >
              {t("lbl.logo_uploading_guideline")}
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                textAlign: "center",
                marginTop: 1,
                color: "#000000",
              }}
            >
              (All fields are required)
            </Typography>
          </Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12} sm={12} md={6}>
                <FormControl
                  fullWidth
                  margin="normal"
                  error={!!errors.businessName}
                >
                  <Controller
                    name="businessName"
                    control={control}
                    defaultValue=""
                    rules={{ required: t("lbl.business_name_required") }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={capitalizeFirstLetter(t("lbl.name_of_business"))}
                        variant="outlined"
                        fullWidth
                        error={!!errors.businessName}
                        helperText={
                          errors.businessName &&
                          `*${errors.businessName.message}`
                        }
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "10px", // Increase border radius
                            borderColor: "#E3E3E3", // Change border color
                            borderWidth: "2px", // Make border solid and 2px
                            "& fieldset": {
                              borderColor: "#E3E3E3", // Ensure initial border color is set
                              borderWidth: "3px", // Ensure initial border width is set
                            },
                            "&:hover fieldset": {
                              borderColor: "#E3E3E3", // Change hover border color
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#FE0000", // Keep the focused border color
                            },
                          },
                          "& .MuiInputLabel-root": {
                            "&.Mui-focused": {
                              color: "#FE0000",
                            },
                          },
                        }}
                      />
                    )}
                  />
                </FormControl>

                <FormControl
                  fullWidth
                  margin="normal"
                  error={!!errors.businessAddress}
                >
                  <Controller
                    name="businessAddress"
                    control={control}
                    defaultValue=""
                    rules={{ required: t("lbl.business_address_required") }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={capitalizeFirstLetter(t("lbl.business_address"))}
                        variant="outlined"
                        fullWidth
                        error={!!errors.businessAddress}
                        helperText={
                          errors.businessAddress &&
                          `*${errors.businessAddress.message}`
                        }
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "10px", // Increase border radius
                            borderColor: "#E3E3E3", // Change border color
                            borderWidth: "2px", // Make border solid and 2px
                            "& fieldset": {
                              borderColor: "#E3E3E3", // Ensure initial border color is set
                              borderWidth: "3px", // Ensure initial border width is set
                            },
                            "&:hover fieldset": {
                              borderColor: "#E3E3E3", // Change hover border color
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#FE0000", // Keep the focused border color
                            },
                          },
                          "& .MuiInputLabel-root": {
                            "&.Mui-focused": {
                              color: "#FE0000",
                            },
                          },
                        }}
                      />
                    )}
                  />
                </FormControl>

                <FormControl
                  fullWidth
                  margin="normal"
                  error={!!errors.companyNumber}
                >
                  <Controller
                    name="companyNumber"
                    control={control}
                    defaultValue=""
                    rules={{ required: t("lbl.company_number_required") }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={capitalizeFirstLetter(t("lbl.company_number"))}
                        variant="outlined"
                        fullWidth
                        type="phone"
                        error={!!errors.companyNumber}
                        helperText={
                          errors.companyNumber &&
                          `*${errors.companyNumber.message}`
                        }
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "10px", // Increase border radius
                            borderColor: "#E3E3E3", // Change border color
                            borderWidth: "2px", // Make border solid and 2px
                            "& fieldset": {
                              borderColor: "#E3E3E3", // Ensure initial border color is set
                              borderWidth: "3px", // Ensure initial border width is set
                            },
                            "&:hover fieldset": {
                              borderColor: "#E3E3E3", // Change hover border color
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#FE0000", // Keep the focused border color
                            },
                          },
                          "& .MuiInputLabel-root": {
                            "&.Mui-focused": {
                              color: "#FE0000",
                            },
                          },
                        }}
                      />
                    )}
                  />
                </FormControl>

                <FormControl
                  fullWidth
                  margin="normal"
                  error={!!errors.companyName}
                >
                  <Controller
                    name="companyName"
                    control={control}
                    defaultValue=""
                    rules={{ required: t("lbl.company_name_required") }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={capitalizeFirstLetter(t("lbl.company_name"))}
                        variant="outlined"
                        fullWidth
                        error={!!errors.companyName}
                        helperText={
                          errors.companyName && `*${errors.companyName.message}`
                        }
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "10px", // Increase border radius
                            borderColor: "#E3E3E3", // Change border color
                            borderWidth: "2px", // Make border solid and 2px
                            "& fieldset": {
                              borderColor: "#E3E3E3", // Ensure initial border color is set
                              borderWidth: "3px", // Ensure initial border width is set
                            },
                            "&:hover fieldset": {
                              borderColor: "#E3E3E3", // Change hover border color
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#FE0000", // Keep the focused border color
                            },
                          },
                          "& .MuiInputLabel-root": {
                            "&.Mui-focused": {
                              color: "#FE0000",
                            },
                          },
                        }}
                      />
                    )}
                  />
                </FormControl>

                <FormControl
                  fullWidth
                  margin="normal"
                  error={!!errors.businessPhoneNumber}
                >
                  <Controller
                    name="businessPhoneNumber"
                    control={control}
                    defaultValue=""
                    rules={{
                      minLength: 8,
                      maxLength: 10,
                      required: t("lbl.business_phone_numer_required"),
                      pattern: {
                        value: /^[0-9]*$/,
                        message: t("lbl.phonenumber_invalid"),
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={capitalizeFirstLetter(
                          t("lbl.business_phone_number")
                        )}
                        variant="outlined"
                        fullWidth
                        type="phone"
                        error={!!errors.businessPhoneNumber}
                        helperText={
                          errors.businessPhoneNumber &&
                          `*${errors.businessPhoneNumber.message}`
                        }
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "10px", // Increase border radius
                            borderColor: "#E3E3E3", // Change border color
                            borderWidth: "2px", // Make border solid and 2px
                            "& fieldset": {
                              borderColor: "#E3E3E3", // Ensure initial border color is set
                              borderWidth: "3px", // Ensure initial border width is set
                            },
                            "&:hover fieldset": {
                              borderColor: "#E3E3E3", // Change hover border color
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#FE0000", // Keep the focused border color
                            },
                          },
                          "& .MuiInputLabel-root": {
                            "&.Mui-focused": {
                              color: "#FE0000",
                            },
                          },
                        }}
                      />
                    )}
                  />
                </FormControl>

                <FormControl
                  fullWidth
                  margin="normal"
                  error={!!errors.contactName}
                >
                  <Controller
                    name="contactName"
                    control={control}
                    defaultValue=""
                    rules={{ required: t("lbl.contact_name_required") }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={capitalizeFirstLetter(t("lbl.contact_name"))}
                        variant="outlined"
                        fullWidth
                        error={!!errors.contactName}
                        helperText={
                          errors.contactName && `*${errors.contactName.message}`
                        }
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "10px", // Increase border radius
                            borderColor: "#E3E3E3", // Change border color
                            borderWidth: "2px", // Make border solid and 2px
                            "& fieldset": {
                              borderColor: "#E3E3E3", // Ensure initial border color is set
                              borderWidth: "3px", // Ensure initial border width is set
                            },
                            "&:hover fieldset": {
                              borderColor: "#E3E3E3", // Change hover border color
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#FE0000", // Keep the focused border color
                            },
                          },
                          "& .MuiInputLabel-root": {
                            "&.Mui-focused": {
                              color: "#FE0000",
                            },
                          },
                        }}
                      />
                    )}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <FormControl fullWidth margin="normal" error={!!errors.email}>
                  <Controller
                    name="email"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: t("lbl.email_required"),
                      maxLength: 50,
                      pattern: {
                        value:
                          /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                        message: t("lbl.email_invalid"),
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={t("lbl.email")}
                        variant="outlined"
                        fullWidth
                        error={!!errors.email}
                        helperText={errors.email && `*${errors.email.message}`}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "10px", // Increase border radius
                            borderColor: "#E3E3E3", // Change border color
                            borderWidth: "2px", // Make border solid and 2px
                            "& fieldset": {
                              borderColor: "#E3E3E3", // Ensure initial border color is set
                              borderWidth: "3px", // Ensure initial border width is set
                            },
                            "&:hover fieldset": {
                              borderColor: "#E3E3E3", // Change hover border color
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#FE0000", // Keep the focused border color
                            },
                          },
                          "& .MuiInputLabel-root": {
                            "&.Mui-focused": {
                              color: "#FE0000",
                            },
                          },
                        }}
                      />
                    )}
                  />
                </FormControl>

                <FormControl fullWidth margin="normal" error={!!errors.city}>
                  <Controller
                    name="city"
                    control={control}
                    defaultValue=""
                    rules={{ required: t("lbl.business_city_required") }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={capitalizeFirstLetter(t("lbl.business_city"))}
                        variant="outlined"
                        fullWidth
                        error={!!errors.city}
                        helperText={errors.city && `*${errors.city.message}`}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "10px", // Increase border radius
                            borderColor: "#E3E3E3", // Change border color
                            borderWidth: "2px", // Make border solid and 2px
                            "& fieldset": {
                              borderColor: "#E3E3E3", // Ensure initial border color is set
                              borderWidth: "3px", // Ensure initial border width is set
                            },
                            "&:hover fieldset": {
                              borderColor: "#E3E3E3", // Change hover border color
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#FE0000", // Keep the focused border color
                            },
                          },
                          "& .MuiInputLabel-root": {
                            "&.Mui-focused": {
                              color: "#FE0000",
                            },
                          },
                        }}
                      />
                    )}
                  />
                </FormControl>

                <FormControl
                  fullWidth
                  margin="normal"
                  error={!!errors.isKosher}
                >
                  <InputLabel
                    sx={{
                      "&.Mui-focused": {
                        color: "#FE0000",
                      },
                    }}
                    id="isKosher-label"
                  >
                    kosher / non kosher
                  </InputLabel>
                  <Controller
                    name="isKosher"
                    rules={{ required: t("lbl.kosher_required") }}
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <Select
                        {...field}
                        labelId="isKosher-label"
                        label="kosher / non kosher"
                        value={field.value || ""}
                        sx={{
                          borderRadius: "10px", // Increase border radius
                          borderColor: "#E3E3E3", // Change border color
                          borderWidth: "2px", // Make border solid and 2px
                          "&.MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "#E3E3E3", // Ensure initial border color is set
                              borderWidth: "3px", // Ensure initial border width is set
                            },
                            "&:hover fieldset": {
                              borderColor: "#E3E3E3", // Change hover border color
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#FE0000", // Keep the focused border color
                            },
                          },
                          "& .MuiInputLabel-root": {
                            "&.Mui-focused": {
                              color: "#FE0000",
                            },
                          },
                        }}
                      >
                        <MenuItem value="">
                          <em>Kosher / non kosher</em>
                        </MenuItem>
                        <MenuItem value={"true"}>Kosher</MenuItem>
                        <MenuItem value={"false"}>Non kosher</MenuItem>
                      </Select>
                    )}
                  />
                  {errors.isKosher && (
                    <Typography ml={1} fontSize="0.75rem" color="error">
                      *{errors.isKosher.message}
                    </Typography>
                  )}
                </FormControl>

                <FormControl
                  fullWidth
                  margin="normal"
                  error={!!errors.foodType}
                >
                  <InputLabel
                    sx={{
                      "&.Mui-focused": {
                        color: "#FE0000",
                      },
                    }}
                    id="foodType-label"
                  >
                    Type of food or business
                  </InputLabel>
                  <Controller
                    name="foodType"
                    control={control}
                    rules={{ required: t("lbl.foodtype_required") }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        value={field.value || ""}
                        labelId="foodType-label"
                        label="Type of food or business"
                        sx={{
                          borderRadius: "10px", // Increase border radius
                          borderColor: "#E3E3E3", // Change border color
                          borderWidth: "2px", // Make border solid and 2px
                          "&.MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "#E3E3E3", // Ensure initial border color is set
                              borderWidth: "3px", // Ensure initial border width is set
                            },
                            "&:hover fieldset": {
                              borderColor: "#E3E3E3", // Change hover border color
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#FE0000", // Keep the focused border color
                            },
                          },
                          "& .MuiInputLabel-root": {
                            "&.Mui-focused": {
                              color: "#FE0000",
                            },
                          },
                        }}
                      >
                        <MenuItem value="">
                          <em>Type of food or business</em>
                        </MenuItem>
                        {foodTypes.map((item) => (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  {errors.foodType && (
                    <Typography ml={1} fontSize="0.75rem" color="error">
                      *{errors.foodType.message}
                    </Typography>
                  )}
                </FormControl>
                <Typography
                  sx={{
                    color: "#000000",
                    marginTop: 1,
                    marginBottom: 1,
                  }}
                >
                  Does the business accept cibus or ten bis card?
                </Typography>
                <FormControl
                  fullWidth
                  margin="normal"
                  error={!!errors.cardType}
                >
                  <InputLabel
                    sx={{
                      "&.Mui-focused": {
                        color: "#FE0000", // Change the color to #FE0000 when focused
                      },
                    }}
                    id="cardType-label"
                  >
                    Please select
                  </InputLabel>
                  <Controller
                    name="cardType"
                    control={control}
                    defaultValue=""
                    rules={{ required: t("lbl.cardtype_required") }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        value={field.value || ""}
                        labelId="cardType-label"
                        label="Please select"
                        sx={{
                          borderRadius: "10px", // Increase border radius
                          borderColor: "#E3E3E3", // Change border color
                          borderWidth: "2px", // Make border solid and 2px
                          "&.MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "#E3E3E3", // Ensure initial border color is set
                              borderWidth: "3px", // Ensure initial border width is set
                            },
                            "&:hover fieldset": {
                              borderColor: "#E3E3E3", // Change hover border color
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#FE0000", // Keep the focused border color
                            },
                          },
                          "& .MuiInputLabel-root": {
                            "&.Mui-focused": {
                              color: "#FE0000",
                            },
                          },
                        }}
                      >
                        <MenuItem value="">
                          <em>Please select</em>
                        </MenuItem>
                        <MenuItem value="tenBisCard">Ten bis card</MenuItem>
                        <MenuItem value="cibusCard">Cibus card</MenuItem>
                        <MenuItem value="both">Both of them</MenuItem>
                        <MenuItem value="nothing">Nothing</MenuItem>
                      </Select>
                    )}
                  />
                  {errors.cardType && (
                    <Typography ml={1} fontSize="0.75rem" color="error">
                      *{errors.cardType.message}
                    </Typography>
                  )}
                </FormControl>

                <Typography className="text-center" color="error">
                  {t("lbl.by_connecting_or_joining_i_confirm_the")}
                  {t("lbl.term_of_use")}
                </Typography>

                <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
                  <Button
                    sx={{
                      backgroundColor: "#FE0000",
                      textTransform: "capitalize",
                      borderRadius: 10,
                      "&:hover": {
                        backgroundColor: "#FE0000",
                      },
                    }}
                    variant="contained"
                    type="submit"
                    size="large"
                    fullWidth
                  >
                    {t("lbl.finish")}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Container>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={registerBusinessApi.loading || getBusinessDetailsApi.loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default OpenNewBusiness;
