import React, { useState, useEffect } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import useApi from "../hooks/useApi";
import promotionApi from "../api/promotion";
import { Box, CircularProgress } from "@mui/material";

const PictureFromDatabase = ({
  setSelectedImageUrl,
}: {
  setSelectedImageUrl: Function;
}) => {
  const [data, setData] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [selectedImage, setSelectedImage] = useState<any>(null);

  const getPicturesApi = useApi(promotionApi.getPicturesFromDatabase);

  useEffect(() => {
    fetchData();
  }, [page]);

  const fetchData = async () => {
    getPicturesApi.request(page);
  };

  useEffect(() => {
    try {
      const newData = getPicturesApi?.data;
      if (newData) {
        setData((prevData) => [...prevData, ...newData?.data]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [getPicturesApi?.data]);

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop !==
        document.documentElement.offsetHeight ||
      getPicturesApi.loading
    ) {
      return;
    }
    setPage((prevPage) => prevPage + 1);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleImageClick = (imageObj) => {
    setSelectedImage(imageObj);
    setSelectedImageUrl(imageObj.imageUrl);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: 2,
      }}
    >
      {data.map((image, index) => (
        <LazyLoadImage
          key={image.imageId}
          alt={""}
          src={image.mediaUrl}
          width={"300px"}
          height={"300px"}
          onClick={() =>
            handleImageClick({ imageUrl: image.mediaUrl, index: index })
          }
          className={selectedImage?.index === index ? "selected" : ""}
        />
      ))}
      {getPicturesApi.loading && <CircularProgress sx={{ color: "#FE0000" }} />}
    </Box>
  );
};

export default PictureFromDatabase;
