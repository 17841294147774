import React, { createContext, useContext, useState } from 'react';
import { List, ListItem, ListItemText, Snackbar } from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

const SnackbarContext = createContext<any>(undefined);

export const SnackbarProvider = ({ children }) => {
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [isSnackbarOpen, setIsSnackbarOpen] = useState<boolean>(false);

  const showSnackbar = (message) => {
    if (message) {
      setIsSnackbarOpen(true);
      setSnackbarMessage(message);
    }
  };

  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setIsSnackbarOpen(false);
  };

  return (
    <SnackbarContext.Provider value={{ showSnackbar }}>
      {children}
      <Snackbar open={isSnackbarOpen} autoHideDuration={2000} onClose={handleCloseSnackbar}>
        <Alert severity="warning" sx={{ width: '100%' }} onClose={handleCloseSnackbar}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = () => {
  return useContext(SnackbarContext);
};
