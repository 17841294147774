import { Button, Col, Figure, Modal, Row, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  AiOutlineAlignRight,
  AiOutlineClose,
  AiOutlineEdit,
  AiOutlineFontSize,
} from "react-icons/ai";
import { FaMusic, FaTrash } from "react-icons/fa";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import MyFloatingButtons from "../inc/MyFloatingButtons";
import { useEffect, useRef, useState } from "react";
import { ICreatePromotionDto } from "../../types/promotion";
import Webcam from "react-webcam";
import IconButton from "@mui/material/IconButton";
import {
  Box,
  FormControlLabel,
  Grid,
  Button as MUIButton,
  Radio,
  RadioGroup,
} from "@mui/material";
import { TfiGallery } from "react-icons/tfi";
import ReplayIcon from "@mui/icons-material/Replay";
import { styled } from "@mui/material/styles";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import promotionApi from "../../api/promotion";
import useApi from "../../hooks/useApi";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { DatePicker } from "react-bootstrap-date-picker";
import TimePicker from "react-bootstrap-time-picker";
import "bootstrap-daterangepicker/daterangepicker.css";
import { v4 as uuidv4 } from "uuid";
import useStorage from "../../hooks/useStorage";
import { IResponseModel } from "../../types/common";
import { useSnackbar } from "../../context/snackbarContext";
import PictureFromDatabase from "../PictureFromDatabase";

const AdvertiseABusiness = ({
  showAdvertiseABusinessModal,
  toggleAdertiseBusinessModal,
  businessDetails,
  getPromotionsApi,
  selectedPromotion,
  setSelectedPromotion,
}) => {
  const { t } = useTranslation();

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    setValue,
  } = useForm<ICreatePromotionDto>();

  const { showSnackbar } = useSnackbar();

  const webcamRef = useRef<any>(null);
  const fileInputRef = useRef<any>(null);
  const [imageSource, setImageSource] = useState<any>(null);
  const [selectedImageUrl, setSelectedImageUrl] = useState<any>(null);
  const [isImageSelected, setIsImageSelected] = useState<boolean>(false);
  const [isDBPicturesVisible, setIsDBPicturesVisible] =
    useState<boolean>(false);
  const [isImageEdited, setIsImageEdited] = useState<boolean>(true);

  const [startDate, setStartDate] = useState<any>(null);
  const [startTime, setStartTime] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [endTime, setEndTime] = useState<any>(null);

  const createPromotionApi = useApi(promotionApi.createPromotion);
  const updatePromotionApi = useApi(promotionApi.updatePromotion);
  const s3StorageService = useStorage();

  const handleSubmitPromotion = async (payload: ICreatePromotionDto) => {
    let fileObj = imageSource;
    if (fileObj instanceof File) {
      console.log("starting upload => ");
      let phoneNumber = businessDetails.businessPhoneNumber.replace(/\+/g, "");
      const fileUploadResponse = await s3StorageService.handleFileUpload(
        fileObj,
        `${uuidv4()}-${new Date().toISOString()}`,
        `business/${phoneNumber}/businessLogo`
      );
      payload.mediaUrl = fileUploadResponse?.location ?? payload.mediaUrl;
      payload.mediaType = "1";
    } else if (!imageSource && selectedImageUrl) {
      payload.mediaUrl = selectedImageUrl;
      payload.mediaType = "1";
    }

    payload.businessId = businessDetails.businessId;
    payload.scheduleType = "oneTime";
    payload.startDate = startDate;
    payload.startTime = startTime;
    payload.endDate = endDate;
    payload.endTime = endTime;

    const { promotion, isReupload } = selectedPromotion ?? {};
    if (promotion && !isReupload) {
      await updatePromotionApi.request(promotion.promotionId, payload);
    } else {
      await createPromotionApi.request(payload);
    }
  };

  useEffect(() => {
    let response: IResponseModel =
      createPromotionApi?.data ?? updatePromotionApi?.data;
    if (response) {
      if (response?.isSuccess) {
        showSnackbar(response?.message);
        getPromotionsApi.request(businessDetails.businessId);
        setImageSource(null);
        setSelectedImageUrl(null);
        setIsImageSelected(false);
        setIsDBPicturesVisible(false);
        setSelectedPromotion(undefined);
        toggleAdertiseBusinessModal(false);
      } else {
        showSnackbar(response?.message || "Something went wrong");
      }
    }
  }, [createPromotionApi?.data, updatePromotionApi?.data]);

  useEffect(() => {
    if (selectedPromotion) {
      const { promotion } = selectedPromotion;
      const entries = Object.entries(promotion);
      entries.forEach(([key, value]: [any, any]) => {
        setValue(key, value);
      });
      setSelectedImageUrl(promotion.mediaUrl);
      setIsImageSelected(true);
    }
  }, [selectedPromotion]);

  const capture = async () => {
    const webCamImageSrc = webcamRef.current.getScreenshot();
    const blob = await fetch(webCamImageSrc).then((res) => res.blob());
    const webCamImagefile = new File([blob], "captured-image.png", {
      type: "image/png",
    });
    setImageSource(webCamImagefile);

    setSelectedImageUrl(webcamRef?.current?.getScreenshot());
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImageSource(file);
      const imageUrl = URL.createObjectURL(file);
      setSelectedImageUrl(imageUrl);
    }
  };

  const handleFileSelect = () => {
    fileInputRef?.current?.click();
  };

  return (
    <>
      <Modal
        show={showAdvertiseABusinessModal}
        onHide={() => {
          setImageSource(null);
          setSelectedImageUrl(null);
          setIsImageSelected(false);
          setIsDBPicturesVisible(false);
          setSelectedPromotion(undefined);
          toggleAdertiseBusinessModal(false);
        }}
        centered
        id="advertise-modal"
      >
        <Modal.Body className="bg-dark" style={{ padding: 0 }}>
          {isImageSelected && (
            <Figure
              className="custom-figure"
              style={{ display: "block", height: "auto" }}
            >
              <div
                className="image-container"
                style={{
                  width: "100%",
                  height: "72vh",
                  backgroundImage: `url(${selectedImageUrl})`,
                }}
              >
                {/* <Figure.Image
                width={"100%"}
                height={"50vh"}
                alt="171x180"
                src={selectedImageUrl}
              /> */}
                <div className="overlay-content">
                  <Figure.Caption>
                    <Row>
                      <Col
                        xs={2}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Link
                          to={"#"}
                          onClick={() => {
                            setImageSource(null);
                            setSelectedImageUrl(null);
                            setIsImageSelected(false);
                            setIsDBPicturesVisible(false);
                            setSelectedPromotion(undefined);
                            toggleAdertiseBusinessModal(false);
                          }}
                        >
                          <AiOutlineClose
                            color="white"
                            style={{ fontWeight: 700 }}
                          />
                        </Link>
                      </Col>
                    </Row>
                    {/* <div
                    style={{
                      position: "absolute",
                      bottom: 15,
                      right: 15,
                      left: 15,
                    }}
                  > */}
                    {/* <Row>
                  <Col> */}
                    <form
                      onSubmit={handleSubmit(handleSubmitPromotion)}
                      style={{ position: "absolute", bottom: 0 }}
                    >
                      <Form.Group className="mb-3" controlId="formGroupEmail">
                        <Controller
                          name="description"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: t("lbl.benefit_description_required"),
                          }}
                          render={({ field }) => (
                            <Form.Control
                              {...field}
                              as="textarea"
                              rows={3}
                              placeholder="What is the offer/benefit? For example 20% discount, 1+1 on starters...."
                              style={{
                                backgroundColor: "transparent",
                                color: "white",
                                height: "200px !important",
                              }}
                            />
                          )}
                        />
                        {errors.description && (
                          <p
                            role="alert"
                            style={{ color: "red", fontWeight: 500 }}
                          >
                            *{errors.description.message}
                          </p>
                        )}
                      </Form.Group>

                      <RadioGroup
                        aria-labelledby="benefit-type"
                        defaultValue="oneTime"
                        name="benefit-type-group"
                      >
                        <DateRangePicker
                          onApply={(event, picker) => {
                            setStartDate(picker.startDate);
                            setEndDate(picker.endDate);
                          }}
                          initialSettings={{
                            opens: "right",
                            drops: "up",
                          }}
                        >
                          <FormControlLabel
                            value="oneTime"
                            control={
                              <Radio
                                sx={{
                                  "&.MuiRadio-root.Mui-checked": {
                                    color: "red",
                                  },
                                }}
                              />
                            }
                            label="One time benefit"
                            sx={{
                              backgroundColor: "transparent",
                              color: "white",
                              "&.MuiRadio-root.Mui-checked": {
                                color: "red",
                              },
                            }}
                          />
                        </DateRangePicker>

                        <DateRangePicker
                          onApply={(event, picker) => {
                            setStartDate(picker.startDate);
                            setEndDate(picker.endDate);
                          }}
                          initialSettings={{
                            opens: "right",
                            drops: "up",
                          }}
                        >
                          <FormControlLabel
                            value="onGoing"
                            control={
                              <Radio
                                sx={{
                                  "&.MuiRadio-root.Mui-checked": {
                                    color: "red",
                                  },
                                }}
                              />
                            }
                            label="Ongoing benefit"
                            sx={{
                              backgroundColor: "transparent",
                              color: "white",
                            }}
                          />
                        </DateRangePicker>
                      </RadioGroup>

                      <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                      >
                        <Grid item xs>
                          <TimePicker
                            value={startTime}
                            style={{
                              width: "130px",
                              appearance: "auto",
                            }}
                            onChange={(time) => {
                              const hours = Math.floor(time / 3600);
                              const minutes = Math.floor((time % 3600) / 60);
                              const today = new Date();
                              today.setUTCHours(hours);
                              today.setUTCMinutes(minutes);
                              today.setUTCSeconds(0);
                              today.setUTCMilliseconds(0);
                              var _startTime = today.toISOString();
                              setStartTime(_startTime);
                            }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TimePicker
                            value={endTime}
                            style={{
                              width: "130px",
                              appearance: "auto",
                            }}
                            onChange={(time) => {
                              const hours = Math.floor(time / 3600);
                              const minutes = Math.floor((time % 3600) / 60);
                              const today = new Date();
                              today.setUTCHours(hours);
                              today.setUTCMinutes(minutes);
                              today.setUTCSeconds(0);
                              today.setUTCMilliseconds(0);
                              var _endTime = today.toISOString();
                              setEndTime(_endTime);
                            }}
                          />
                        </Grid>
                      </Grid>

                      <div
                        className="d-grid gap-2"
                        style={{ margin: "20px 60px" }}
                      >
                        <Button variant="danger" type="submit" size="lg">
                          {t("lbl.advertising_the_benefit")}
                        </Button>
                      </div>
                    </form>
                    {/* </Col>
                </Row> */}
                    {/* </div> */}
                  </Figure.Caption>
                </div>
              </div>
            </Figure>
          )}

          {!isImageSelected && !isDBPicturesVisible && (
            <div className="panel">
              <div
                className="panel panel-header bg-dark"
                style={{ borderTopLeftRadius: 5, borderTopRightRadius: 5 }}
              >
                <div
                  className="d-flex justify-content-space-between align-items-center"
                  style={{ padding: 15 }}
                >
                  <Button
                    className="bg-transparent"
                    style={{ border: 0 }}
                    onClick={() => {
                      setImageSource(null);
                      setSelectedImageUrl(null);
                      setIsImageSelected(false);
                      setIsDBPicturesVisible(false);
                      setSelectedPromotion(undefined);
                      toggleAdertiseBusinessModal(false);
                    }}
                  >
                    <AiOutlineClose color="white" />
                  </Button>
                  <h4 className="text-white">{t("lbl.upload_benefit")}</h4>
                  <Button
                    onClick={() => {
                      toggleAdertiseBusinessModal(false);
                    }}
                    variant="outline-light"
                    style={{ borderRadius: 100 }}
                  >
                    {t("lbl.to_business_profile")}
                  </Button>
                </div>
              </div>
              <div className="panel panel-body bg-dark">
                {imageSource === null ? (
                  <Webcam
                    audio={false}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    style={{ width: "100%", height: 300 }}
                  />
                ) : (
                  <div style={{ width: "100%", height: 300 }}>
                    <img
                      src={selectedImageUrl}
                      style={{ height: "auto", maxWidth: "100%" }}
                    />
                  </div>
                )}
              </div>
              <div className="panel panel-footer bg-dark">
                <div
                  className="d-flex justify-content-space-between align-items-center"
                  style={{ padding: 15 }}
                >
                  {!imageSource ? (
                    <>
                      <input
                        type="file"
                        accept="image/*"
                        style={{ display: "none" }}
                        ref={fileInputRef}
                        onChange={handleFileChange}
                      />
                      <IconButton onClick={handleFileSelect}>
                        <TfiGallery style={{ color: "white" }} />
                      </IconButton>

                      <Button
                        className="bg-transparent"
                        style={{ border: 0 }}
                        onClick={() => capture()}
                      >
                        <img
                          src="/iphone-button-icon.png"
                          width={30}
                          height={30}
                        />
                      </Button>
                      <Button
                        variant="outline-light"
                        style={{ borderRadius: 100, maxWidth: 130 }}
                        onClick={() => {
                          setIsDBPicturesVisible(true);
                        }}
                      >
                        {t("lbl.picture_from_the_database")}
                      </Button>
                    </>
                  ) : (
                    <>
                      <IconButton
                        aria-label="replay"
                        onClick={() => setImageSource(null)}
                      >
                        <ReplayIcon sx={{ color: "white" }} />
                      </IconButton>

                      <Button
                        variant="outline-light"
                        style={{ borderRadius: 100, maxWidth: 130 }}
                        onClick={() => {
                          setIsImageSelected(true);
                        }}
                      >
                        Continue
                      </Button>
                    </>
                  )}
                </div>
              </div>
            </div>
          )}

          {!isImageSelected && isDBPicturesVisible && (
            <div
              className="panel"
              style={{ width: "100%", height: "500px", overflowY: "scroll" }}
            >
              <div
                className="panel panel-header bg-dark"
                style={{ borderTopLeftRadius: 5, borderTopRightRadius: 5 }}
              >
                <div
                  className="d-flex justify-content-space-between align-items-center"
                  style={{ padding: 15 }}
                >
                  <Button
                    className="bg-transparent"
                    style={{ border: 0 }}
                    onClick={() => {
                      setImageSource(null);
                      setSelectedImageUrl(null);
                      setIsImageSelected(false);
                      setIsDBPicturesVisible(false);
                      setSelectedPromotion(undefined);
                      toggleAdertiseBusinessModal(false);
                    }}
                  >
                    <AiOutlineClose color="white" />
                  </Button>
                  <h4 className="text-white">{t("lbl.database_pictures")}</h4>
                  <Button
                    variant="outline-light"
                    style={{ borderRadius: 100 }}
                    onClick={() => {
                      setIsDBPicturesVisible(false);
                    }}
                  >
                    back
                  </Button>
                </div>
              </div>
              <div
                className="panel panel-body bg-dark"
                style={{ minHeight: "340px" }}
              >
                <PictureFromDatabase
                  setSelectedImageUrl={setSelectedImageUrl}
                />
              </div>
              <div className="panel panel-footer bg-dark">
                <div className="" style={{ padding: 15 }}>
                  {selectedImageUrl && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        background: "rgba(254, 0, 0, 0.5)",
                        borderRadius: 10,

                        position: "absolute",
                        padding: 1,
                        top: 30,
                        left: 30,
                        right: 30,
                      }}
                    >
                      <IconButton
                        aria-label="replay"
                        onClick={() => setSelectedImageUrl(null)}
                      >
                        <ReplayIcon sx={{ color: "white" }} />
                      </IconButton>

                      <Button
                        variant="outline-light"
                        style={{ borderRadius: 100, maxWidth: 130 }}
                        onClick={() => {
                          setIsImageSelected(true);
                          setIsDBPicturesVisible(false);
                        }}
                      >
                        Continue
                      </Button>
                    </Box>
                  )}
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  showAdvertiseABusinessModal: state.showAdvertiseABusinessModal,
});

const dispatchStateToProps = (dispatch) => ({
  toggleAdertiseBusinessModal: (flag) => {
    dispatch({
      type: "TOGGLE_ADVERTISE_A_BUSINESS_MODAL",
      payload: flag,
    });
  },
});

export default connect(
  mapStateToProps,
  dispatchStateToProps
)(AdvertiseABusiness);
