import { IAppState } from "../../types/common";

const initialState: IAppState = {
  showAccountCreatedModal: false,
  showBusinessDeleteConfirmationModal: false,
  showBusinessActivateConfirmationModal: false,
  showAdvertiseABusinessModal: false,
  showAddPostCameraModal: false,
  businessList: [],
  searchQuery: "",
  selectedBusinessIDs: [],
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case "TOGGLE_ACCOUNT_CREATED_MODAL":
      return {
        ...state,
        showAccountCreatedModal: action.payload,
      };
    case "TOGGLE_BUSINESS_DELETE_CONFIRMATION_MODAL":
      return {
        ...state,
        showBusinessDeleteConfirmationModal: action.payload,
      };
    case "TOGGLE_BUSINESS_ACTIVATE_CONFIRMATION_MODAL":
      return {
        ...state,
        showBusinessActivateConfirmationModal: action.payload,
      };
    case "TOGGLE_ADVERTISE_A_BUSINESS_MODAL":
      return {
        ...state,
        showAdvertiseABusinessModal: action.payload,
      };
    case "TOGGLE_ADD_POST_CCAMERA_MODAL":
      return {
        ...state,
        showAddPostCameraModal: action.payload,
      };
    case "SAVE_BUSINESS_LIST":
      return {
        ...state,
        businessList: action.payload,
      };
    case "SET_SEARCH_QUERY":
      return {
        ...state,
        searchQuery: action.payload,
      };
    case "SET_SELECTED_BUSINESSIDS":
      return {
        ...state,
        selectedBusinessIDs: action.payload,
      };
    default:
      return state;
  }
};

export default rootReducer;
