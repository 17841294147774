import client from "./client";
import { ISigninRequest, ISignupRequest } from "../types/user";

const BACKEND_API_PREFIX = process.env.REACT_APP_PROD_AUTH_API_PREFIX;

client.defaults.baseURL = BACKEND_API_PREFIX;

const signin = (payload: ISigninRequest) => {
  return client.post(process.env.REACT_APP_AUTH_SIGNIN ?? "", payload);
};

const signup = (payload: ISignupRequest) => {
  return client.post(process.env.REACT_APP_AUTH_SIGNUP ?? "", payload);
};

export default {
  signin,
  signup,
};
