import { useState } from "react";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { IMenuItem } from "../types/common";
import { Link, useLocation } from "react-router-dom";

export default function DrawerMenu({
  isLTR,
  drawerWidth,
  open,
  handleDrawerToggle,
}: {
  isLTR: boolean;
  drawerWidth: number;
  open: boolean;
  handleDrawerToggle: () => void;
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const menuList: IMenuItem[] = [
    { name: t("lbl.menu_main"), link: "/home" },
    { name: t("lbl.open_a_new_business"), link: "/open-business" },
    { name: t("lbl.menu_complaints"), link: "/complaints" },
    { name: t("lbl.menu_revenues"), link: "/revenue" },
    { name: t("lbl.menu_expenses"), link: "/expense" },
    { name: t("lbl.menu_business"), link: "/business" },
    { name: t("lbl.menu_customers"), link: "/customer" },
    { name: t("lbl.menu_coupons"), link: "/coupons" },
    { name: t("lbl.menu_certified_mail"), link: "/certified-mail" },
  ];

  const { pathname } = useLocation();

  return (
    <>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            backgroundColor: "#fe0000 !important",
          },
        }}
        variant={isSmallScreen ? "temporary" : "permanent"}
        open={isSmallScreen ? open : true}
        onClose={isSmallScreen ? handleDrawerToggle : undefined}
        anchor={isLTR ? "left" : "right"}
      >
        <Toolbar style={{ justifyContent: "center", margin: "15px" }}>
          <Link to={"/home"}>
            <img src="./feast-logo.png" width={120} height={50} />
          </Link>
        </Toolbar>
        <Divider />
        <List>
          {menuList.map((menu, index) => (
            <ListItem
              key={index}
              disablePadding
              component={Link}
              to={menu.link}
            >
              <ListItemButton
                selected={menu.link === pathname}
                sx={{
                  "&.Mui-selected": {
                    backgroundColor: "#d10b11",
                  },
                }}
                onClick={isSmallScreen ? handleDrawerToggle : undefined}
              >
                {isLTR ? (
                  <>
                    <ListItemText sx={{ color: "white" }} primary={menu.name} />
                    <ListItemIcon>
                      <KeyboardArrowRightIcon sx={{ color: "white" }} />
                    </ListItemIcon>
                  </>
                ) : (
                  <>
                    <ListItemIcon>
                      <KeyboardArrowLeftIcon sx={{ color: "white" }} />
                    </ListItemIcon>
                    <ListItemText sx={{ color: "white" }} primary={menu.name} />
                  </>
                )}
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />
      </Drawer>
    </>
  );
}
