import client from "./client";
import { ICreatePromotionDto } from "../types/promotion";

const BACKEND_API_PREFIX = process.env.REACT_APP_PROD_AUTH_API_PREFIX;

client.defaults.baseURL = BACKEND_API_PREFIX;

const getAllBusinessPromotions = (id: string) => {
  return client.get(`${process.env.REACT_APP_BUSINESS_PROMOTIONS ?? ""}/${id}`);
};

const createPromotion = (payload: ICreatePromotionDto) => {
  return client.post(process.env.REACT_APP_PROMOTIONS ?? "", payload);
};

const updatePromotion = (id: string, payload: ICreatePromotionDto) => {
  return client.put(`${process.env.REACT_APP_PROMOTIONS ?? ""}/${id}`, payload);
};

const deactivatePromotion = (id: string) => {
  return client.put(
    `${process.env.REACT_APP_DEACTIVATE_PROMOTIONS ?? ""}/${id}`
  );
};

const getPicturesFromDatabase = (pageNum: number) => {
  return client.get(
    `${
      process.env.REACT_APP_PICTURES_FROM_DATABASE ?? ""
    }?pageNumber=${pageNum}&pageSize=30`
  );
};

const getAllComplaints = () => {
  return client.get(`${process.env.REACT_APP_ALL_COMPLAINTS ?? ""}`);
};

export default {
  getAllBusinessPromotions,
  createPromotion,
  updatePromotion,
  deactivatePromotion,
  getPicturesFromDatabase,
  getAllComplaints,
};
