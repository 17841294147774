import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComments, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { capitalizeFirstLetter } from "../../utils/Helper";
import { BsFillPencilFill } from "react-icons/bs";
import useApi from "../../hooks/useApi";
import businessApi from "../../api/business";
import notificationApi from "../../api/notification";
import { Backdrop, CircularProgress } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { useSnackbar } from "../../context/snackbarContext";
import { IBusinessResponseDto } from "../../types/business";
const { format } = require("date-fns");
import { connect, useDispatch, useSelector } from "react-redux";
import {
  IAppState,
  IEditComments,
  IPushNotificationDto,
} from "../../types/common";
import SMSModal from "../modals/SMSModal";
import CommentsModal from "../modals/CommentsModal";

const MyTable = () => {
  const { t } = useTranslation();

  const [showSMSModal, setShowSMSModal] = useState(false);
  const [showCommentsModal, setShowCommentsModal] = useState(false);
  const [selectedBusiness, setSelectedBusiness] = useState<any>(undefined);
  const [commentsDetails, setCommentsDetails] = useState<
    IEditComments | undefined
  >(undefined);

  const toggleSMSModal = () => {
    setShowSMSModal(!showSMSModal);
  };

  const toggleCommentsModal = () => {
    setShowCommentsModal(!showCommentsModal);
  };

  const dispatch = useDispatch();
  const sendNotificationApi = useApi(notificationApi.sendNotification);
  const deactivateBusinessApi = useApi(businessApi.deactivateBusiness);
  const activateBusinessApi = useApi(businessApi.activateBusiness);

  const { showSnackbar } = useSnackbar();

  const columns: GridColDef[] = [
    {
      field: "businessName",
      headerName: capitalizeFirstLetter(t("lbl.business_name")),
      renderCell: ({ row }) => {
        return (
          <Link
            to={`/business-profile/${row.businessId}`}
            style={{ color: "black", textDecoration: "none" }}
          >
            {row.businessName}
          </Link>
        );
      },
      width: 150,
    },
    {
      field: "businessAddress",
      headerName: capitalizeFirstLetter(t("lbl.address")),
    },
    {
      field: "contactName",
      headerName: capitalizeFirstLetter(t("lbl.contact_name")),
      resizable: true,
      width: 150,
    },
    {
      field: "companyNumber",
      headerName: capitalizeFirstLetter(t("lbl.company_number")),
      width: 150,
    },
    {
      field: "companyName",
      headerName: capitalizeFirstLetter(t("lbl.company_name")),
      width: 150,
    },
    {
      field: "businessPhoneNumber",
      headerName: capitalizeFirstLetter(t("lbl.phone")),
    },
    {
      field: "email",
      headerName: capitalizeFirstLetter(t("lbl.email")),
      resizable: true,
      width: 200,
    },
    {
      field: "isActive",
      headerName: capitalizeFirstLetter(t("lbl.account_status")),
      valueGetter: (params) => {
        return params.value === true ? "Active" : "Inactive";
      },
      width: 150,
    },
    {
      field: "createdAt",
      headerName: "Registration Date",
      valueGetter: (params) => {
        const formattedDate = format(new Date(params.value), "dd/MMM/yyyy");
        return formattedDate;
      },
      resizable: true,
      width: 200,
    },
    {
      field: "deactivateDate",
      headerName: "Deactivate Date",
      valueGetter: (params) => {
        const formattedDate = params?.value
          ? format(new Date(params.value), "dd/MMM/yyyy")
          : "";
        return formattedDate;
      },
      resizable: true,
      width: 200,
    },
    {
      field: "deactivateComments",
      headerName: "Deactivate Comments",
      resizable: true,
      width: 200,
      renderCell: ({ row }) => {
        return (
          <div className="d-flex justify-content-center">
            {row?.deactivateComments &&
              row?.deactivateComments?.substring(0, 20) + "..."}
            {row.deactivateComments && (
              <Link
                to={"#"}
                className="me-2 text-danger"
                onClick={() => {
                  setCommentsDetails({
                    businessId: row.businessId,
                    commentsText: row.deactivateComments,
                    isDeactivateComments: true,
                  });
                  toggleCommentsModal();
                }}
                style={{ marginLeft: "10px" }}
              >
                <BsFillPencilFill
                  color="#dc3545 !important"
                  style={{ float: "right" }}
                />
              </Link>
            )}
          </div>
        );
      },
    },
    {
      field: "activateDate",
      headerName: "Activate Date",
      valueGetter: (params) => {
        const formattedDate = params?.value
          ? format(new Date(params?.value), "dd/MMM/yyyy")
          : "";
        return formattedDate;
      },
      resizable: true,
      width: 200,
    },
    {
      field: "activateComments",
      headerName: "Activate Comments",
      resizable: true,
      width: 200,
      renderCell: ({ row }) => {
        return (
          <div className="d-flex justify-content-center">
            {row?.activateComments &&
              row?.activateComments?.substring(0, 20) + "..."}
            {row?.activateComments && (
              <Link
                to={"#"}
                className="me-2 text-danger"
                onClick={() => {
                  setCommentsDetails({
                    businessId: row.businessId,
                    commentsText: row.activateComments,
                    isDeactivateComments: false,
                  });
                  toggleCommentsModal();
                }}
                style={{ marginLeft: "10px" }}
              >
                <BsFillPencilFill
                  color="#dc3545 !important"
                  style={{ float: "right" }}
                />
              </Link>
            )}
          </div>
        );
      },
    },
    {
      field: "",
      headerName: "SMS",
      renderCell: ({ row }) => {
        return (
          <div className="d-flex justify-content-center">
            {/* <Link to={"#"} className="me-2 text-danger">
              <FontAwesomeIcon icon={faEnvelope} />
            </Link> */}
            <Link
              to={"#"}
              className="me-2 text-danger"
              onClick={() => {
                setSelectedBusiness(row);
                toggleSMSModal();
              }}
            >
              <FontAwesomeIcon icon={faComments} />
            </Link>
          </div>
        );
      },
    },
    {
      field: "businessId",
      headerName: capitalizeFirstLetter(t("lbl.edit_business")),
      renderCell: ({ row }) => {
        return (
          <Link
            to={`/open-business/${row.businessId}`}
            className="me-2"
            style={{ color: "#dc3545" }}
          >
            Edit
          </Link>
        );
      },
      width: 150,
    },
  ];

  const getBusinessesApi = useApi(businessApi.getAllBusinesses);

  useEffect(() => {
    getBusinessesApi.request("");
  }, []);

  useEffect(() => {
    let businessApiResponse: any = getBusinessesApi?.data;
    if (businessApiResponse) {
      if (businessApiResponse?.data && businessApiResponse?.data?.length > 0) {
        dispatch({
          type: "SAVE_BUSINESS_LIST",
          payload: businessApiResponse?.data,
        });
      } else {
        showSnackbar(businessApiResponse?.message || "No data found");
      }
    }

    let notificationApiResponse: any = sendNotificationApi?.data;
    if (notificationApiResponse) {
      showSnackbar(notificationApiResponse?.message);
      setSelectedBusiness(undefined);
    }
  }, [getBusinessesApi?.data, sendNotificationApi?.data]);

  let filteredList: any[] = useSelector(
    (state: IAppState) => state.businessList
  );

  let searchQuery: string = useSelector(
    (state: IAppState) => state.searchQuery
  );

  if (filteredList.length && searchQuery) {
    filteredList = filteredList?.filter((item) => {
      return (
        item.businessName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.contactName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.companyName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.businessPhoneNumber
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
      );
    });
  }

  const handleSendPushNotification = async (payload: IPushNotificationDto) => {
    await sendNotificationApi.request(payload);
  };

  const handleUpdateComments = async (payload: any, isDeactivate: boolean) => {
    if (isDeactivate)
      await deactivateBusinessApi.request(commentsDetails?.businessId, payload);
    else
      await activateBusinessApi.request(commentsDetails?.businessId, payload);

    getBusinessesApi.request("");
    setCommentsDetails(undefined);
  };

  const [selectionModel, setSelectionModel] = useState([]);

  const handleSelectionModelChange = (newSelection) => {
    setSelectionModel(newSelection);
    dispatch({ type: "SET_SELECTED_BUSINESSIDS", payload: newSelection });
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          overflowX: "auto",
        }}
      >
        <DataGrid
          rows={filteredList}
          loading={getBusinessesApi.loading}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[10, 20, 30, 40, 50]}
          getRowId={(row) => row.businessId}
          sx={{
            width: {
              xs: "200px", // width for small screens
              sm: "400px", // width for small-medium screens
              md: "700px", // width for medium screens
              lg: "1000px", // width for large screens
              xl: "1300px", // width for extra-large screens
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              fontWeight: "bold",
            },
            "& .MuiDataGrid-footerContainer": {
              justifyContent: "center",
            },
            "& .MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel":
              {
                margin: "0",
              },
            "--DataGrid-overlayHeight": "520px",
            "& .Mui-checked, & .MuiCheckbox-indeterminate": {
              color: "#fe0000 !important",
            },
          }}
          disableColumnMenu
          autoHeight
          checkboxSelection
          rowSelectionModel={selectionModel}
          onRowSelectionModelChange={handleSelectionModelChange}
        />
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={false}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <SMSModal
        showModal={showSMSModal}
        toggleModal={toggleSMSModal}
        businessDetails={selectedBusiness}
        handleSendNotification={handleSendPushNotification}
      />

      <CommentsModal
        showModal={showCommentsModal}
        toggleModal={toggleCommentsModal}
        commentsDetails={commentsDetails}
        handleUpdateComments={handleUpdateComments}
      />
    </>
  );
};

export default MyTable;
