import * as React from "react";
import type { FC, ReactNode } from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import i18n from "i18next";
import DrawerMenu from "./DrawerMenu";
import { Outlet } from "react-router";
import MyNavbar from "./inc/MyNavbar";

interface MainLayoutProps {
  children?: ReactNode;
}

const drawerWidth = 200;

const MainLayout: FC<MainLayoutProps> = ({ children }) => {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const isLTR: boolean = i18n.language === "en";

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <MyNavbar
        handleDrawerToggle={handleDrawerToggle}
        drawerWidth={drawerWidth}
      />
      <DrawerMenu
        isLTR={isLTR}
        drawerWidth={drawerWidth}
        open={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
      />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          marginTop: isSmallScreen ? "" : "60px",
          // marginLeft: isLTR ? { sm: `${drawerWidth}px` } : 0,
          // marginRight: !isLTR ? { sm: `${drawerWidth}px` } : 0,
        }}
      >
        <Toolbar />
        {children || <Outlet />}
      </Box>
    </Box>
  );
};

export default MainLayout;
