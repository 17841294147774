import { Button, Modal, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import i18n from "i18next";
import { useState } from "react";
import { IActivateBusinessDto } from "../../types/business";

const BusinessActivateConfirmation = ({
  showBusinessActivateConfirmationModal,
  toggleBusinessActivateConfirmModal,
  handleConfirmActivateAction,
}) => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState<IActivateBusinessDto | undefined>(
    undefined
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    handleConfirmActivateAction(formData);
    toggleBusinessActivateConfirmModal(false);
  };

  return (
    <>
      <Modal
        show={showBusinessActivateConfirmationModal}
        onHide={() => toggleBusinessActivateConfirmModal(false)}
        size="sm"
        centered
      >
        <Form onSubmit={handleSubmit}>
          <Modal.Body dir={`${i18n.language === "en" ? "ltr" : "rtl"}`}>
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ flexDirection: "column" }}
            >
              <p className="mt-3">
                {t("lbl.are_you_sure_activate_business_text")}
              </p>
            </div>

            <Form.Group controlId="deactivateModal.deactivateReason">
              <Form.Control
                as="textarea"
                rows={4}
                placeholder={t("lbl.comments")}
                onChange={(e) =>
                  setFormData({ ...formData, comments: e.target.value })
                }
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer dir={`${i18n.language === "en" ? "ltr" : "rtl"}`}>
            <Button variant="primary" type="submit">
              {t("lbl.yes")}
            </Button>
            <Button
              variant="default"
              onClick={() => toggleBusinessActivateConfirmModal(false)}
            >
              {t("lbl.no")}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  showBusinessActivateConfirmationModal:
    state.showBusinessActivateConfirmationModal,
});

const dispatchStateToProps = (dispatch) => ({
  toggleBusinessActivateConfirmModal: (flag) => {
    dispatch({
      type: "TOGGLE_BUSINESS_ACTIVATE_CONFIRMATION_MODAL",
      payload: flag,
    });
  },
});

export default connect(
  mapStateToProps,
  dispatchStateToProps
)(BusinessActivateConfirmation);
