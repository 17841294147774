import React, { useState } from "react";
import { Button, Figure } from "react-bootstrap";
import "./../../css/MyFigureComponent.css"; // Import your CSS file
import "holderjs";
import { FaTrash, FaEdit } from "react-icons/fa";
import { Link } from "react-router-dom";
import { IPromotionResponseDto } from "../../types/common";
import Chip from "@mui/material/Chip";
import PromotionDeactivateConfirmation from "../modals/PromotionDeactivateConfirmation";

function MyFigureComponent({
  promotion,
  handleReupload,
  handleEdit,
  handleDeactivatePromotion,
}: {
  promotion: IPromotionResponseDto;
  handleReupload: Function;
  handleEdit: Function;
  handleDeactivatePromotion: Function;
}) {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const handleConfirmAction = () => {
    handleDeactivatePromotion(promotion.promotionId);
  };

  return (
    <>
      <Figure className="custom-figure">
        {promotion.mediaType === "1" ? (
          <div
            className="image-container"
            style={{ backgroundImage: `url(${promotion.mediaUrl})` }}
          >
            <div className="overlay-content">
              <Figure.Caption>
                <Chip
                  label="Reupload"
                  onClick={() => handleReupload(promotion)}
                  sx={{ backgroundColor: "red", color: "white" }}
                />
                <div style={{ position: "absolute", top: 25, right: 50 }}>
                  <Link to={"#"} onClick={() => handleEdit(promotion)}>
                    <FaEdit style={{ fontSize: "1.5em", color: "white" }} />
                  </Link>
                </div>
                <div style={{ position: "absolute", top: 25, right: 15 }}>
                  <Link to={"#"} onClick={() => setIsModalVisible(true)}>
                    <FaTrash style={{ fontSize: "1.5em", color: "white" }} />
                  </Link>
                </div>
                <div
                  style={{
                    position: "absolute",
                    bottom: 15,
                    right: 15,
                    textAlign: "center",
                  }}
                >
                  <span className="price-tag">{promotion.description}</span>
                </div>
              </Figure.Caption>
            </div>
          </div>
        ) : (
          <div className="image-container" style={{ backgroundImage: `none` }}>
            <video controls width="100%" height="auto">
              <source src={promotion.mediaUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        )}
      </Figure>

      <PromotionDeactivateConfirmation
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        handleConfirmAction={handleConfirmAction}
      />
    </>
  );
}

export default MyFigureComponent;
