import React from "react";
import ReactDOM from "react-dom/client";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { Provider } from "react-redux";
import App from "./App";
//import reportWebVitals from './reportWebVitals';
import store from "./redux/store";
import enTranslation from "./locales/en.json";
import arTranslation from "./locales/ar.json";
import heTranslation from "./locales/he.json";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: enTranslation },
    ar: { translation: arTranslation },
    he: { translation: heTranslation },
  },
  lng: "en", // Default language
  fallbackLng: "he", // Fallback language
  interpolation: {
    escapeValue: false, // React already does escaping
  },
});

const myElement: Element | any = document.getElementById("root");
const root: any = ReactDOM.createRoot(myElement);
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

//reportWebVitals();
