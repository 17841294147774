import { useState } from "react";

export default (apiFunc) => {
  const [data, setData] = useState<any>(null);
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const request = async (...args) => {
    setLoading(true);
    setError(""); // Reset error before making a new request
    try {
      const result = await apiFunc(...args);
      setData(result.data);
      return { data: result.data, error: null }; // Return structured result
    } catch (err: any) {
      const message = err.response?.data || err.message || "Unexpected Error!";
      setError(message);
      return { data: null, error: message }; // Return structured result
    } finally {
      setLoading(false);
    }
  };

  return {
    data,
    error,
    loading,
    request,
  };
};
