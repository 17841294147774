import AuthProvider from "./context/authProvider";
import { SnackbarProvider } from "./context/snackbarContext";
import Routes from "./routes";
window.Buffer = window.Buffer || require("buffer").Buffer;

const App = () => {
  return (
    <AuthProvider>
      <SnackbarProvider>
        <Routes />
      </SnackbarProvider>
    </AuthProvider>
  );
};

export default App;
