import React, { useState, useEffect } from 'react';
import "../css/login.css";
import { Button, Form, Image } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faEye, faKey } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../context/authProvider';
import { useSnackbar } from '../context/snackbarContext';
import useApi from '../hooks/useApi';
import authApi from "../api/auth";
import { Backdrop, CircularProgress } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ISigninRequest, IAuthResponse } from '../types/user';

const Login = () => {
    const { t } = useTranslation();
    const [passwordFieldType, setPasswordFieldType] = useState<boolean>(true);
    const [isMessageVisible, setIsMessageVisible] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");

    const {
        register,
        formState: { errors },
        handleSubmit
    } = useForm<ISigninRequest>();
    const authSigninApi = useApi(authApi.signin);
    const { setToken } = useAuth();
    const { showSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const onSubmit: SubmitHandler<ISigninRequest> = async (data) => await authSigninApi.request(data);

    useEffect(() => {
        let response: IAuthResponse = authSigninApi?.data;
        if (response) {
            if (response?.data && response?.data?.accessToken) {
                setToken(response.data.accessToken);
                navigate("/", { replace: true });
            } else {
                //showSnackbar(response?.message || "Something went wrong");
                setIsMessageVisible(true);
                setErrorMessage(response?.message || "Something went wrong");
            }
        }
    }, [authSigninApi?.data])

    return (
        <>
            <div className="d-lg-flex half" dir={`${i18n.language === 'en' ? 'ltr' : 'rtl'}`}>
                <div className="contents order-1 order-md-1">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="text-center mt-5">
                                    <Image src="./images/logo.png" width={"200px"} />
                                    <h5 className="mt-3 mb-3 font-weight-600">{t("lbl.login_page_sub_heading")}</h5>
                                </div>
                                <form onSubmit={handleSubmit(onSubmit)} style={{ padding: "0px 15px" }}>

                                    <div className='d-flex align-items-center mb-3'>
                                        <input
                                            id="email"
                                            placeholder={t("lbl.email")}
                                            className='form-control font-weight-600'
                                            style={i18n.language === 'he' ? { paddingRight: 35 } : { paddingLeft: 35 }}
                                            {...register("email", {
                                                required: t("lbl.email_required"),
                                                maxLength: 50,
                                                pattern: {
                                                    value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                                                    message: t("lbl.email_invalid")
                                                }
                                            })}
                                        />
                                        <span style={i18n.language === 'he' ? { position: "absolute", marginRight: 15 } : { position: 'absolute', marginLeft: 15 }}>
                                            <FontAwesomeIcon icon={faEnvelope} />
                                        </span>
                                    </div>
                                    {errors.email && (
                                        <p role="alert" style={{ color: "red", fontWeight: 500 }}>*{errors.email.message}</p>
                                    )}

                                    <div className='d-flex align-items-center mb-3' style={{ position: "relative" }}>
                                        <input
                                            id="password"
                                            type={passwordFieldType ? 'password' : 'text'}
                                            placeholder={t("lbl.password")}
                                            className='form-control font-weight-600'
                                            style={i18n.language === 'he' ? { paddingRight: 35 } : { paddingLeft: 35 }}
                                            {...register("password", { required: true, maxLength: 50 })}
                                        />
                                        <span style={i18n.language === 'he' ? { position: "absolute", marginRight: 15 } : { position: 'absolute', marginLeft: 15 }}>
                                            <FontAwesomeIcon icon={faKey} />
                                        </span>
                                        <span className='show-password-icon' style={i18n.language === 'he' ? { position: "absolute", left: 15 } : { position: 'absolute', right: 15 }} onClick={() => setPasswordFieldType(!passwordFieldType)}>
                                            <FontAwesomeIcon icon={faEye} />
                                        </span>
                                    </div>
                                    {errors.password?.type === "required" && (
                                        <p role="alert" style={{ color: "red", fontWeight: 500 }}>*{t("lbl.password_required")}</p>
                                    )}

                                    <Form.Group className="mb-3" id="formGridCheckbox">
                                        <label><input type="checkbox" className="form-check-input" /> {t("lbl.rememberMe")}</label>
                                    </Form.Group>
                                    <div className="d-grid gap-2">
                                        <Button variant='danger' type="submit" size='lg'>{t("lbl.entrance")}</Button>
                                    </div>

                                    {/* <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: 30 }}>
                                        {t("lbl.do_you_have_an_accout")}&nbsp;<Link to={"/signup"} className='text-danger font-weight-600'>{t("lbl.signup")}</Link>
                                    </div> */}

                                    {isMessageVisible && (<div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: 30, color: "red" }}>
                                        {errorMessage}
                                    </div>)}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg order-2 order-md-2" style={{ backgroundImage: "url('./images/background-image.jpg')", minWidth: "75%" }}></div>
            </div>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={authSigninApi.loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
}

export default Login;
